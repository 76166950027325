import React, { Component } from "react";
import HighDiv from "./modeling/HighDiv";

export default class InfoField extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          outline: "1px solid rgb(124, 124, 124)",
          backgroundColor: "rgb(231, 231, 231)",
          boxShadow:
            "inset 2px 2px 2px rgb(148, 148, 148),inset -2px -2px 2px rgb(148, 148, 148)",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "calc(400%)", // FIXME: is this correct?
            fontWeight: "bold",
            color: "rgb(213, 213, 213)",
            overflow: "hidden",
          }}
        >
          LOG
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            paddingRight: 100,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: "100%",
              overflow: "auto",
              overflowX: "hidden",
              paddingLeft: 10,
              paddingTop: 10,
              paddingRight: -10,
              paddingBottom: -10,
            }}
          >
            {this.props.text}
          </div>
        </div>
        <HighDiv
          text="Clear"
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            width: 80,
            height: 30,
            backgroundColor: "rgb(200, 200, 200)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "grab",
            marginBottom: 10,
          }}
          clickable={true}
          onClick={() => this.props.onClear()}
        />
      </div>
    );
  }
}
