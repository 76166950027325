import React, { Component } from "react";
import Consts from "./Consts";

export default class CoinCanvas extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    const canvas = this.ref.current;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#000000";
    ctx.beginPath();
    ctx.rect(0, 0, Consts.cellDim.w, Consts.cellDim.h);
    ctx.arc(
      Consts.cellDim.w / 2,
      Consts.cellDim.h / 2,
      Consts.cellDim.h / 2.3,
      0,
      2 * Math.PI
    );
    ctx.fill("evenodd");
  }

  render() {
    return <canvas ref={this.ref} />;
  }
}
