import Rules from "./Rules";

export default class GameEngine {
  constructor() {
    this.reset();
  }

  reset() {
    this.state = {
      board: Rules.initBoard(),
      player: 0, // 0,1, represents whose turn it is, first move is :env
      moveId: 0, // beginning of the game
      score: 0, // this score is a game score displayed over the board
      playerScores: [0, 0], // this is a player score that is used during inference
      over: false,
      winner: 0,
      lastMove: undefined,
    };
  }

  clone() {
    let copy = new GameEngine();
    for (let key in this.state) {
      if (key === "board") {
        copy.state[key] = this.state[key].map((row) => {
          return [...row];
        });
      } else if (key === "playerScores") {
        copy.state[key] = [...this.state[key]];
      } else {
        copy.state[key] = this.state[key];
      }
    }
    return copy;
  }

  nMoves(player) {
    // NOTE: for env player we can place either 2 or 4 in one of 16 squares
    // NOTE: for env we have all 16 cells with value 2, and then all 16 cells with value 4
    // NOTE: for real player we can have one of 4 moves (left,right,up,down)
    return player == 0 ? 32 : 4;
  }

  nPlayers() {
    return 2; // ["env", "player"],
  }

  // FIXME: should this be outside of the game?
  inputAdapter() {
    // NOTE: don't want to put dependensies on tf inside engine.
    //tf.tensor4d(
    // NOTE: the "batch" direction should not be here
    return {
      dims: [4, 4, 1],
      input: this.state.board.map((row) => row.map((el) => [el])),
    };
  }

  getAvailableMoves() {
    // only provides moves for the current player
    // This function is needed so that we can prune bad moves returned by the model
    if (this.state.player == 0) {
      // env
      let cells = Rules.getFreeCells(this.state.board);
      //console.log(cells);
      // FIXME: HOW TO PROPERLY SET PROBABILITIES?????
      // NOTE: WE CANNOT PUT PROBABILITIES HERE< SINCE FUNCTION HAS TOBE DETERMINISTIC...
      // this.state.moveId < 2
      let cells2 = cells.map((cell) => {
        return cell[0] * 4 + cell[1];
      });
      //console.log("cells2", cells2);
      // FIXME: WHAT TO DO HERE??
      if (true || this.state.moveId < 2) return cells2;
      let cells4 = cells.map((cell) => {
        return cell[0] * 4 + cell[1] + 16;
      });
      return [...cells2, ...cells4];
    }

    // for real player we need to check which moves we can do.
    let ret = [];
    for (let move = 0; move < this.nMoves(1); move++) {
      if (Rules.canMoveBoard(this.state.board, move)) ret.push(move);
    }
    return ret;
  }

  // FIXME: this can be very optimized!!!!!
  checkGameOver() {
    this.state.over = this.getAvailableMoves().length == 0;
    if (this.state.over) {
      //console.log("game is over");
      this.winner = 0; // env always wins, but in other games winner might be dependent on the check / lastmove

      // there is no need to update scores, the score in gameOver is jsut the same
      //this.state.playerScores[0] = 100000;
      //this.state.playerScores[1] = -100000;
    }
  }

  doMove(move) {
    // NOTE: returns false is move was not possible, and the state is NOT UPDATED!!!!
    //console.log("doing move", move, "state:", this.state);
    let ret =
      this.state.player == 0
        ? Rules.createElement(this.state.board, move)
        : Rules.moveBoard(this.state.board, move);
    if (ret == -1) {
      return false;
    }
    // NOTE: for env player returned score is 0
    this.state.score += ret;
    this.state.playerScores[0] = -this.state.score; // env player tried to minimize score
    this.state.playerScores[1] = this.state.score; // real player tries to maximize score
    // NOTE: first 2 moves are done by env, then turn switches to real player
    if (this.state.moveId > 0) {
      this.state.player = 1 - this.state.player;
    }
    this.state.moveId += 1;
    this.state.lastMove = move;
    // PROFILE: 5.3s / 60s
    this.checkGameOver();
    return true;
  }
}
