import React, { Component } from "react";
import Styles from "./modeling/Styles";

const space = 2;

class Tab extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isHigh: false,
  };
  render() {
    return (
      <React.Fragment>
        {this.props.id == "HIDE" ? (
          <div />
        ) : (
          <div
            style={{
              position: "absolute",
              width: this.props.totalWidth - 2 * space,
              top: 40, //"5%",
              height: "100%", //"calc(100%-50)", // "95%"
              right: this.props.right ? -this.props.totalWidth : undefined,
              left: this.props.right ? undefined : -this.props.totalWidth,
              transform:
                this.props.tab == this.props.id
                  ? "translateX(" +
                    this.props.totalWidth * (this.props.right ? -1 : 1) +
                    "px)"
                  : undefined,
              transition: "all 0.5s ease 0s",
              backgroundColor: "rgb(226, 226, 226)",
              //boxShadow:
              //  this.props.tab == this.props.id ? "-4px 0px 4px grey" : "",
              borderLeft: "" + space + "px rgb(113, 113, 113) solid",
              borderRight: "" + space + "px rgb(113, 113, 113) solid",
              zIndex: 50,
              overflowY: "auto",
            }}
          >
            {this.props.inner}
          </div>
        )}
        <div
          style={{
            position: "absolute",
            right: this.props.right ? this.props.buttonX : undefined,
            left: this.props.right ? undefined : this.props.buttonX,
            top: space,
            width: this.props.tabW,
            height: 40, //"5%",
            backgroundColor: "rgb(113, 113, 113)",
            boxShadow:
              "0px " +
              space +
              "px 0px rgb(113, 113, 113), 0px -" +
              space +
              "px 0px rgb(113, 113, 113)",
            zIndex: 100,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: space,
              width: this.props.innerTabW,
              height: "100%",
              backgroundColor:
                this.props.tab == this.props.id
                  ? "rgb(255, 245, 224)"
                  : this.state.isHigh
                  ? "rgb(226, 226, 226)"
                  : "rgb(215, 215, 215)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              userSelect: "none", // for unselectable text
              fontWeight: "bold",
              color: "rgb(56, 56, 56)",
            }}
            onClick={() => {
              this.props.onClick(this.props.id);
            }}
            onMouseEnter={() => this.setState({ isHigh: true })}
            onMouseLeave={() => this.setState({ isHigh: false })}
          >
            {this.props.id}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.tabW =
      (this.props.width - space) / Object.keys(this.props.tabs).length + space;
    this.innerTabW = this.tabW - 2 * space;
  }
  componentDidMount() {
    // NOTE: this is for initial transition when loading the page
    this.props.onHide(Object.keys(this.props.tabs)[0] == "HIDE");
    this.setState({ tab: Object.keys(this.props.tabs)[0] });
  }
  state = { tab: "" };
  render() {
    return (
      <div style={{ ...Styles.dummyStyle, overflow: "hidden" }}>
        {Object.keys(this.props.tabs).map((key, id) => {
          return (
            <Tab
              key={key}
              id={key}
              color={this.props.tabs[key].color}
              tab={this.state.tab}
              buttonX={
                this.props.right
                  ? this.props.width - id * (this.tabW - space) - this.tabW
                  : id * (this.tabW - space)
              }
              onClick={(clickId) => {
                this.setState({ tab: clickId });
                this.props.onHide(clickId == "HIDE");
              }}
              inner={this.props.tabs[key].inner}
              tabW={this.tabW}
              innerTabW={this.innerTabW}
              totalWidth={this.props.width}
              right={this.props.right}
            />
          );
        })}
      </div>
    );
  }
}
