// ml = model loader
// getModelScoreBatch(batch) => [{id,OutputAdapter(output)}]
// batch = [id, InputAdapter(state)]

// can we assume score is always between 1 and -1????
// even for 2048??
// FIXME: introduce "scale" of the score!! scale is being set by the game
const scoreScale = 1;
function GetRandScore() {
  return (Math.random() * 2 - 1) * scoreScale;
}

// FIXME: should policy be normalized?
// should it even return negative?
function GetRandPolicy(nMoves) {
  let policy = [];
  for (let i = 0; i < nMoves; i++) policy.push(Math.random()); // * 2 - 1);
  return policy;
}

export default class RandomModelLoader {
  constructor(nMoves) {
    this.nMoves = nMoves;
    this.nEvalCalls = 0;
  }

  getModelScoreBatch(toEval) {
    let ret = [];
    for (let item of toEval) {
      // {id, input}
      ret.push({
        id: item.id,
        output: [[GetRandScore()], GetRandPolicy(this.nMoves)],
      });
    }
    //console.log("prediction:", JSON.stringify(ret));
    return ret;
  }

  getModelScoreBatchAsync(toEval, cb) {
    this.nEvalCalls++;
    let res = this.getModelScoreBatch(toEval);
    cb(res);
  }
}
