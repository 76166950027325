function InitBoard() {
  return [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ];
}

function GetFreeCells(b) {
  let cells = [];
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      if (b[i][j] == 0) cells.push([i, j]);
    }
  }
  return cells;
}

// NOTE: returns -1 if its a bad move or 0 if good (score)
function CreateElement(b, move) {
  let cells = GetFreeCells(b);
  if (cells.length == 0) return -1;
  let value = 2;
  if (move >= 16) {
    value = 4;
    move -= 16;
  }
  if (b[Math.floor(move / 4)][move % 4] != 0) return -1;
  b[Math.floor(move / 4)][move % 4] = value;
  return 0;
}

// NOTE: returns false if move is bad
function CanCreateElement(b, move) {
  let cells = GetFreeCells(b);
  if (cells.length == 0) return false;
  if (move >= 16) {
    move -= 16;
  }
  return b[Math.floor(move / 4)][move % 4] == 0;
}

function MoveColUp(b, j) {
  let score = 0;
  let moved = false;
  let merged = false;
  for (let i = 1; i < 4; i++) {
    if (b[i][j] == 0) continue;
    let empty = i;
    while (empty > 0 && b[empty - 1][j] == 0) empty--;
    // move tile
    if (
      b[empty][j] == 0 &&
      (empty == 0 || merged || b[empty - 1][j] != b[i][j])
    ) {
      b[empty][j] = b[i][j];
      b[i][j] = 0;
      merged = false;
      moved = true;
    } else if (!merged && empty > 0 && b[empty - 1][j] == b[i][j]) {
      merged = true;
      b[empty - 1][j] = 2 * b[i][j];
      score += b[i][j];
      b[i][j] = 0;
      moved = true;
    }
  }
  return moved ? score : -1;
}

function MoveRowLeft(b, i) {
  let score = 0;
  let moved = false;
  let merged = false;
  for (let j = 1; j < 4; j++) {
    if (b[i][j] == 0) continue;
    let empty = j;
    while (empty > 0 && b[i][empty - 1] == 0) empty--;
    // move tile
    if (
      b[i][empty] == 0 &&
      (empty == 0 || merged || b[i][empty - 1] != b[i][j])
    ) {
      b[i][empty] = b[i][j];
      b[i][j] = 0;
      merged = false;
      moved = true;
    } else if (!merged && empty > 0 && b[i][empty - 1] == b[i][j]) {
      merged = true;
      b[i][empty - 1] = 2 * b[i][j];
      score += b[i][j];
      b[i][j] = 0;
      moved = true;
    }
  }
  return moved ? score : -1;
}

function MoveColDown(b, j) {
  let score = 0;
  let moved = false;
  let merged = false;
  for (let i = 2; i >= 0; i--) {
    if (b[i][j] == 0) continue;
    let empty = i;
    while (empty < 3 && b[empty + 1][j] == 0) empty++;
    // move tile
    if (
      b[empty][j] == 0 &&
      (empty == 3 || merged || b[empty + 1][j] != b[i][j])
    ) {
      b[empty][j] = b[i][j];
      b[i][j] = 0;
      merged = false;
      moved = true;
    } else if (!merged && empty < 3 && b[empty + 1][j] == b[i][j]) {
      merged = true;
      b[empty + 1][j] = 2 * b[i][j];
      score += b[i][j];
      b[i][j] = 0;
      moved = true;
    }
  }
  return moved ? score : -1;
}

function MoveRowRight(b, i) {
  let score = 0;
  let moved = false;
  let merged = false;
  for (let j = 2; j >= 0; j--) {
    if (b[i][j] == 0) continue;
    let empty = j;
    while (empty < 3 && b[i][empty + 1] == 0) empty++;
    // move tile
    if (
      b[i][empty] == 0 &&
      (empty == 3 || merged || b[i][empty + 1] != b[i][j])
    ) {
      b[i][empty] = b[i][j];
      b[i][j] = 0;
      merged = false;
      moved = true;
    } else if (!merged && empty < 3 && b[i][empty + 1] == b[i][j]) {
      merged = true;
      b[i][empty + 1] = 2 * b[i][j];
      score += b[i][j];
      b[i][j] = 0;
      moved = true;
    }
  }
  return moved ? score : -1;
}

function MoveBoardUp(b) {
  let moved = false;
  let score = 0;
  for (let j = 0; j < 4; j++) {
    let addScore = MoveColUp(b, j);
    if (addScore != -1) {
      moved = true;
      score += addScore;
    }
  }
  return moved ? score : -1;
}

function MoveBoardDown(b) {
  let moved = false;
  let score = 0;
  for (let j = 0; j < 4; j++) {
    let addScore = MoveColDown(b, j);
    if (addScore != -1) {
      moved = true;
      score += addScore;
    }
  }
  return moved ? score : -1;
}

function MoveBoardLeft(b) {
  let moved = false;
  let score = 0;
  for (let i = 0; i < 4; i++) {
    let addScore = MoveRowLeft(b, i);
    if (addScore != -1) {
      moved = true;
      score += addScore;
    }
  }
  return moved ? score : -1;
}

function MoveBoardRight(b) {
  let moved = false;
  let score = 0;
  for (let i = 0; i < 4; i++) {
    let addScore = MoveRowRight(b, i);
    if (addScore != -1) {
      moved = true;
      score += addScore;
    }
  }
  return moved ? score : -1;
}

function MoveBoard(b, dir) {
  // NOTE: dir is int 0,1,2,3: left, right, up, down
  if (dir == 0) return MoveBoardLeft(b);
  if (dir == 1) return MoveBoardRight(b);
  if (dir == 2) return MoveBoardUp(b);
  //if (dir == "down")
  return MoveBoardDown(b);
}

function CopyArray(b) {
  return b.map((row) => {
    return [...row];
  });
}

function CanMoveBoard(b, move) {
  let copy = CopyArray(b);
  return MoveBoard(copy, move) != -1;
}

const Rules = {
  initBoard: InitBoard,
  moveBoard: MoveBoard,
  canMoveBoard: CanMoveBoard,
  createElement: CreateElement,
  canCreateElement: CanCreateElement,
  getFreeCells: GetFreeCells,
};

export default Rules;
