import React, { Component } from "react";
import {
  default as ModelingUi,
  totalWidth as modelWidth,
} from "./modeling/MainModelUi";
import InferenceUi from "./inference/InferenceUi";
import TrainingUi from "./training/TrainingUi";
import Styles from "./modeling/Styles";
import Tabs from "./Tabs";
import Games from "./Games";
import GameController from "./MainGameController";
import PlayerOptions from "./PlayerOptions";
import { default as Connect4 } from "./games/connect4/GameUi";
import { default as Game2048 } from "./games/2048/GameUi";
import ResizeObserver from "react-resize-observer";

function GetTabs(context) {
  return {
    Modeling: {
      inner: (
        <ModelingUi
          ref={context.modelingRef}
          gameInputDims={context.state.gameInputDims}
          gameNMoves={context.state.gameNMoves}
          onLoadedModelsChange={(modelNames) => {
            context.setState({
              loadedModelNames: [...modelNames, "RANDOM"],
            });
          }}
        />
      ),
    },
    Training: {
      inner: (
        <TrainingUi
          selectedGame={context.state.selectedGame}
          modelingRef={context.modelingRef}
          modelList={context.state.loadedModelNames}
        />
      ),
    },
    Inference: {
      inner: (
        <InferenceUi
          ref={context.inferenceRef}
          selectedGame={context.state.selectedGame}
          modelList={context.state.loadedModelNames}
          updatableProps={
            context.state.inferenceProps ? context.state.inferenceProps : {}
          }
          onUpdateProps={(update) => {
            context.setState({ inferenceProps: update });
          }}
          onTest={(cb) =>
            context.gameControllerRef.current.evalCurrentState(cb)
          }
          onTestMcts={(cb) =>
            context.gameControllerRef.current.evalCurrentStateMcts(cb)
          }
          onTrain={(cb) =>
            context.gameControllerRef.current.trainOnCurrentState(cb)
          }
        />
      ),
    },
    HIDE: {},
  };
}

const menuWidth = modelWidth + 30; // 606px
const gamesWidth = 200;

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.activeGameRef = React.createRef();
    this.modelingRef = React.createRef();
    this.playerOptionsRef = React.createRef();
    this.inferenceRef = React.createRef();
    this.gameControllerRef = React.createRef();
    this.gameBboxRef = React.createRef();
  }

  handleTabsHide(hide) {
    this.setState({ menuWidth: hide ? 0 : menuWidth });
  }
  handleGamesHide(hide) {
    this.setState({ gamesWidth: hide ? 0 : gamesWidth });
  }

  onUpdateInferenceProps(update) {
    console.log("update called", update);
  }

  state = {
    menuWidth: 0,
    gamesWidth: 0,
    gameState: {},
    loadedModelNames: ["RANDOM"],
  };
  render() {
    return (
      <div
        style={{ ...Styles.dummyStyle, backgroundColor: "rgb(236, 236, 236)" }}
      >
        <Tabs
          right
          tabs={GetTabs(this)}
          width={menuWidth}
          onHide={this.handleTabsHide.bind(this)}
        />
        <Games
          width={gamesWidth}
          onHide={this.handleGamesHide.bind(this)}
          onGameChanged={(name) => this.setState({ selectedGame: name })}
        />
        <div
          style={{
            position: "absolute",
            left: this.state.gamesWidth,
            top: 0,
            width:
              "calc(100% - " +
              (this.state.menuWidth + this.state.gamesWidth) +
              "px)",
            height: "100%",
            //backgroundColor: "rgb(118, 210, 131)",
            transition: "width 0.5s ease 0s, left 0.5s ease 0s",
            overflow: "auto",
          }}
        >
          <div
            key={
              this.state.selectedGame +
              "_auxkey" /* FIXME: this is done so that all internal sub components including controller get reset on state change*/
            }
            style={{
              position: "absolute",
              //left: "50%",
              //top: 50,
              //transform: "translateX(-50%)",
              //width: 1000,
              //height: 750 + 40, // ratio 3:4
              left: "10%",
              top: "10%",
              width: "80%",
              height: "80%",
              outline: "2px solid rgb(125, 125, 125)",
            }}
          >
            <div
              style={{
                ...Styles.dummyStyle,
                top: 42,
                height: "calc(100% - 42px - 63px)",
                //outline: "3px solid black",
                overflow: "hidden",
              }}
            >
              <ResizeObserver
                onResize={(rect) => {
                  // NOTE: this is needed so that games are fit inside the main game area
                  // NOTE2: games should consume the resize callback and take appropriate actions
                  this.setState({ gameBbox: rect });
                }}
              />
              {!this.state.selectedGame ? (
                <div />
              ) : this.state.selectedGame == "Connect4" ? (
                <Connect4
                  bbox={this.state.gameBbox}
                  gameState={this.state.gameState}
                  doMove={(move) => {
                    this.gameControllerRef.current.externalMove(move);
                  }}
                  resetGame={() => this.gameControllerRef.current.reset()}
                />
              ) : this.state.selectedGame == "2048" ? (
                <Game2048
                  bbox={this.state.gameBbox}
                  gameState={this.state.gameState}
                  doMove={(move) => {
                    this.gameControllerRef.current.externalMove(move);
                  }}
                  resetGame={() => this.gameControllerRef.current.reset()}
                />
              ) : (
                <div
                  style={{
                    ...Styles.dummyStyle,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    userSelect: "none", // for unselectable text
                  }}
                >
                  {"GAME BOARD: " + this.state.selectedGame}
                </div>
              )}
            </div>
            {this.state.selectedGame ? (
              <div
                style={{
                  position: "absolute",
                  top: 42 + 10 + 2,
                  left: 10,
                  height: 40,
                  paddingLeft: 10,
                  paddingRight: 10,
                  //width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  userSelect: "none", // for unselectable text
                  backgroundColor: "rgb(201, 201, 201)",
                }}
              >
                {this.state.selectedGame}
              </div>
            ) : (
              <div />
            )}
            {/*TODO: need to figure out how to get the number of players from the currently active game*/}
            <PlayerOptions
              modelList={this.state.loadedModelNames}
              ref={this.playerOptionsRef}
              nPlayers={2 /* FIXME: pass from engine*/}
              activePlayer={this.state.gameState.player}
            />
            <GameController
              ref={this.gameControllerRef}
              modelingRef={this.modelingRef}
              playerOptionsRef={this.playerOptionsRef}
              inferenceRef={this.inferenceRef}
              selectedGame={this.state.selectedGame}
              onNewGameState={(newState) =>
                this.setState({ gameState: newState })
              }
              onGameInputAdapterDims={(dims) => {
                this.setState({ gameInputDims: [...dims] });
              }}
              onGameNMoves={(size) => {
                this.setState({ gameNMoves: size });
              }}
              inferenceProps={this.state.inferenceProps}
            />
          </div>
        </div>
      </div>
    );
  }
}
