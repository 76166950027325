import React, { Component } from "react";
import Consts from "./Consts";

const totalHeight = 4 * Consts.cellSize + 5 * Consts.space;
const totalWidth = 4 * Consts.cellSize + 5 * Consts.space;

function GetBboxScale(bbox) {
  if (!bbox) return 1;
  //console.log(bbox);
  let maxScale = 0.6;
  return Math.min(
    (bbox.height / totalHeight) * maxScale,
    (bbox.width / totalWidth) * maxScale
  );
}

export default class GameUi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.addEventListener("keydown", (event) => {
      switch (event.keyCode) {
        case 65: // a
          this.executePlayerMove(0 /*"left"*/);
          break;
        case 68: // d
          this.executePlayerMove(1 /*"right"*/);
          break;
        case 83: // s
          this.executePlayerMove(3 /*"down"*/);
          break;
        case 87: // w
          this.executePlayerMove(2 /*"up"*/);
          break;
        default:
          break;
      }
    });
  }

  executePlayerMove(move) {
    console.log("executing move:", move);
    if (this.props.gameState.player != 1 || this.props.gameState.over) {
      console.log("not your move or game over");
      return;
    }
    this.props.doMove(move);
  }

  static getDerivedStateFromProps(props, state) {
    return { bboxScale: GetBboxScale(props.bbox) };
  }

  render() {
    let board = this.props.gameState ? this.props.gameState.board : undefined;
    let score =
      this.props.gameState && this.props.gameState.score
        ? this.props.gameState.score
        : 0;
    let over = this.props.gameState && this.props.gameState.over;
    //console.log(this.props.gameState);
    return (
      <div
        style={{
          position: "absolute",
          height: totalHeight,
          width: totalWidth,
          left: "50%",
          top: "50%",
          transform:
            "translate(-50%,-50%)" +
            (this.state.bboxScale ? "scale(" + this.state.bboxScale + ")" : ""),
          transformOrigin: "center",
          //outline: "2px solid rgb(125, 125, 125)",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: totalWidth,
            height: totalHeight,
            backgroundColor: "rgb(135, 135, 135)",
            //overflow: "hidden",
            //border: "1px solid black",
            borderRadius: 5,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 5,
              top: -40,
              height: 30,
              fontSize: 30,
            }}
          >
            {"SCORE: " + score}
          </div>
          {board ? (
            board.map((row, i) => {
              return row.map((cell, j) => {
                return (
                  <div
                    key={i + "_" + j}
                    style={{
                      position: "absolute",
                      left: Consts.space + (Consts.space + Consts.cellSize) * j,
                      top: Consts.space + (Consts.space + Consts.cellSize) * i,
                      color: cell > 4 ? "white" : "rgb(46, 46, 46)",
                      width: Consts.cellSize,
                      height: Consts.cellSize,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      userSelect: "none", // for unselectable text
                      fontSize: Math.floor(Consts.cellSize / 3),
                      backgroundColor: Consts.cellColors[cell],
                      borderRadius: 4,
                    }}
                  >
                    {cell == 0 ? "" : cell}
                  </div>
                );
              });
            })
          ) : (
            <div />
          )}
          {over ? (
            <div
              onClick={this.props.resetGame}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: Math.floor(Consts.cellSize / 2),
                backgroundColor: "rgba(145, 145, 145, 0.7)",
              }}
            >
              GAME OVER
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}
