import React, { Component } from "react";
import Consts from "./Consts";
import Styles from "./Styles";
import Coin from "./Coin";
import CoinCanvas from "./CoinCanvas";
import Rules from "./Rules";

// NOTES:
// 1. Need to observe on scale for mouse moves
// 2. Need to autoscale??

const totalHeight = (Consts.boardDim.h + 1) * Consts.cellDim.h;
const totalWidth = (Consts.boardDim.w + 2) * Consts.cellDim.w;

function GetGameOverDiv(winner, onclick) {
  return (
    <div
      style={{
        ...Styles.boardStyle,
        top: Consts.cellDim.h,
        height: Consts.boardDim.h * Consts.cellDim.h,
        backgroundColor: "rgba(255,255,255,0.8)",
        color: "black",
      }}
      onClick={onclick}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "50%",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            fontSize: 50,
            textAlign: "center",
          }}
        >
          {"GAME OVER"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: 0,
          width: "100%",
          height: "50%",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "24%",
            width: "52%",
            top: 0,
            fontSize: 50,
          }}
        >
          {winner == -1 ? "DRAW" : "WINNER: " + winner}
        </div>
      </div>
    </div>
  );
}

function GetBboxScale(bbox) {
  if (!bbox) return 1;
  //console.log(bbox);
  let maxScale = 0.8;
  return Math.min(
    (bbox.height / totalHeight) * maxScale,
    (bbox.width / totalWidth) * maxScale
  );
}

function initBg() {
  let b = [];
  for (let i = 0; i < Consts.boardDim.h + 1; i++) {
    for (let j = 0; j < Consts.boardDim.w; j++) {
      b.push([i, j]);
    }
  }
  return b;
}

export default class GameUi extends Component {
  constructor(props) {
    super(props);
    this.boardRef = React.createRef();
    this.boardBg = initBg();
  }

  state = { currentX: 0, bboxScale: 1 };

  getMouseX(x) {
    x -= this.boardRef.current.getBoundingClientRect().left;
    //console.log(x);
    let posX = Math.min(
      Math.floor(x / (Consts.cellDim.w * this.state.bboxScale)),
      Consts.boardDim.w - 1
    );
    posX = Math.max(0, posX);
    this.setState({ currentX: posX });
    return posX;
  }

  onMouseMove(e) {
    if (this.props.gameState.over) return;
    this.getMouseX(e.clientX);
  }

  executeMove(x) {
    //console.log("executing move:", x);
    this.props.doMove(x);
  }

  onClick(e) {
    //console.log(e);
    //console.log(e.target.getBoundingClientRect());
    let x = this.getMouseX(e.clientX);
    this.executeMove(x);
  }

  getBoardDiv() {
    return (
      <div
        style={Styles.boardStyle}
        onMouseEnter={this.onMouseMove.bind(this)}
        onMouseMove={this.onMouseMove.bind(this)}
        onClick={this.onClick.bind(this)}
        ref={this.boardRef}
      >
        {this.props.gameState.over ? (
          <div />
        ) : (
          <Coin
            key={
              "temp0" +
              this.props.gameState.player /*NOTE: this is to redraw canvas*/
            }
            pos={{ x: this.state.currentX, y: Consts.boardDim.h }}
            player={this.props.gameState.player}
            opacity={0.7}
          />
        )}
        {/*<Coin
          key={
            "temp1" +
            this.props.gameState.player
          }
          pos={{
            x: this.state.currentX,
            y: this.props.gameState.board[this.state.currentX].length,
          }}
          player={this.props.gameState.player}
          opacity={0.5}
        />*/}
        {this.props.gameState.board.map((col, i) => {
          return col.map((el, j) => {
            return (
              <Coin
                key={i + "_" + j}
                pos={{ x: i, y: j }}
                player={el}
                opacity={1}
              />
            );
          });
        })}
        {this.boardBg.map((cell) => (
          <div
            key={"cell" + cell[1] + cell[0]}
            style={{
              position: "absolute",
              width: Consts.cellDim.w,
              height: Consts.cellDim.h,
              left: cell[1] * Consts.cellDim.w,
              bottom: cell[0] * Consts.cellDim.h,
              opacity: this.state.currentX == cell[1] ? 0.7 : 1,
            }}
          >
            {cell[0] + 1 == Consts.boardDim.h + 1 ? <div /> : <CoinCanvas />}
          </div>
        ))}
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    return { bboxScale: GetBboxScale(props.bbox) };
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: totalHeight,
          width: totalWidth,
          left: "50%",
          top: "50%",
          transform:
            "translate(-50%,-50%)" +
            (this.state.bboxScale ? "scale(" + this.state.bboxScale + ")" : ""),
          transformOrigin: "center",
          //outline: "2px solid rgb(125, 125, 125)",
        }}
      >
        {this.getBoardDiv()}
        {this.props.gameState.over ? (
          GetGameOverDiv(this.props.gameState.winner, this.props.resetGame)
        ) : (
          <div />
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: 2,
            backgroundColor: "black",
            width: (Consts.boardDim.w + 2) * Consts.cellDim.w,
          }}
        />
      </div>
    );
  }
}
