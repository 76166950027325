import React, { Component } from "react";
import Consts from "./Consts";
import Styles from "./Styles";
import { palette } from "./Palette";
import HighDiv from "./HighDiv";

const templates = {
  model1: {
    ui: {
      text: "Connect4",
      color: "rgb(254, 227, 93)",
    },
    nodes: {
      in1: "in",
      cb1: "cb",
      out1: "ou",
      out2: "ou",
      dense1: "de",
      dense2: "de",
    },
    edges: [
      ["in1", "cb1"],
      ["cb1", "dense1"],
      ["cb1", "dense2"],
      ["dense1", "out1"],
      ["dense2", "out2"],
    ],
    params: {
      in1: { shape: [2, 6, 7] },
      cb1: { layers: 10, filters: 16 },
      dense1: { units: 16 },
      dense2: { units: 16 },
      out1: { units: 1, activation: "tanh" },
      out2: { units: 7, activation: "softmax" },
    },
  },
  model2: {
    ui: {
      text: "Model 2",
      color: "rgb(93, 104, 254)",
    },
    nodes: { in1: "in", db1: "db", out1: "ou" },
    edges: [
      ["in1", "db1"],
      ["db1", "out1"],
    ],
  },
  model3: {
    ui: {
      text: "Model 3",
      color: "rgb(254, 93, 184)",
    },
    nodes: { in1: "in", cb1: "cb", out1: "ou" },
    edges: [
      ["in1", "cb1"],
      ["cb1", "out1"],
    ],
  },
};

export default class Templates extends Component {
  // props has graph
  render() {
    let heights = [(2 * Consts.cellSize) / 2];
    Object.keys(palette).map((key) => {
      heights.push(
        heights[heights.length - 1] +
          2 * Consts.cellSize +
          (2 * Consts.cellSize) / 2
      );
    });
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -1,
            height: 30,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(245, 245, 245)",
            backgroundColor: "rgb(125, 125, 125)",
            fontWeight: "bold",
            userSelect: "none", // for unselectable text
          }}
        >
          TEMPLATES
        </div>
        <div
          style={{
            ...Styles.dummyStyle,
            top: 30,
            backgroundColor: "rgb(240,240,240)",
            overflowY: "scroll",
            paddingRight: 20,
          }}
        >
          <div
            style={{
              ...Styles.dummyStyle,
              height: heights[heights.length - 1] + 2 * Consts.cellSize,
            }}
          >
            {Object.keys(templates).map((key, id) => {
              let obj = templates[key];
              return (
                <HighDiv
                  id={key}
                  key={key}
                  text={obj.ui.text}
                  style={{
                    position: "absolute",
                    border: "1px solid black",
                    left: (2 * Consts.cellSize) / 2,
                    top: heights[id],
                    width: 6 * Consts.cellSize,
                    height: 2 * Consts.cellSize,
                    backgroundColor: obj.ui.color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "grab",
                  }}
                  clickable={true}
                  onClick={() => this.props.loadModel(templates[key])}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export { templates };
