import InferenceEngine from "./../inference/InferenceEngine";
import RandomModelLoader from "./../inference/RandomModelLoader";
import TfModelLoader from "./../inference/TfModelLoader";

function GetTime() {
  return new Date().getTime();
}

export default class ParallelController {
  constructor(props) {
    // props = {engine, inferenceOptions, playerOptions, playerTypes}
    this.props = props;
    this.initEngine = props.engine.clone();
    this.initEngine.reset();
    let modelNames = this.props.selectedModels;
    // NOTE: MLs is a set of sets of ML objects since MCTS for every player requires
    // to know model for every player
    this.mls = [];
    // NOTE: if no model is selected (or player is not a computer) the RANDOM model will be returned.
    //console.log("using the following ml models:", JSON.stringify(modelNames));
    for (let i = 0; i < modelNames.length; i++) {
      let ml = [];
      for (let j = 0; j < modelNames[i].length; j++) {
        let name = modelNames[i][j];
        if (name == "RANDOM")
          ml.push(new RandomModelLoader(this.initEngine.nMoves(j)));
        else {
          let model = this.props.models[name];
          if (!model) {
            console.log("model does not exist");
          }
          ml.push(
            new TfModelLoader(model, this.props.inferenceProps.evalBatchSize)
          );
        }
      }
      this.mls.push(ml);
    }
  }

  playBatch(nGames, gameFinishCb, trainingDataCb) {
    this.engines = [];
    for (let i = 0; i < nGames; i++) {
      this.engines.push(this.initEngine.clone());
    }
    for (let i = 0; i < nGames; i++) {
      this.playRec(i, gameFinishCb, trainingDataCb);
    }
  }

  playRec(engineId, onDone, trainingDataCb) {
    //console.log("playrec called on", engineId);
    let wrappedCb = (ok) => {
      if (!ok || !this.props.enabled) {
        onDone(this.engines[engineId].state);
      } else {
        this.playRec(engineId, onDone, trainingDataCb);
      }
    };
    this.moveAsync(this.engines[engineId], wrappedCb, trainingDataCb);
  }

  moveAsync(engine, cb, trainingDataCb) {
    if (engine.state.over) {
      cb(false);
      return;
    }
    let player = engine.state.player;
    InferenceEngine.getMoveAsync(
      this.props.playerTypes[player],
      engine,
      this.mls[player],
      this.props.inferenceProps,
      (move, simData) => {
        // NOTE: returning the state and training data before the next move is made
        trainingDataCb(player, engine.inputAdapter(), simData);
        engine.doMove(move);
        cb(true);
      }
    );
  }
}
