import React, { Component } from "react";
import HighDiv from "./modeling/HighDiv";
import Styles from "./modeling/Styles";
import InferenceEngine from "./inference/InferenceEngine";
import RandomModelLoader from "./inference/RandomModelLoader";
import TfModelLoader from "./inference/TfModelLoader";

import * as tf from "@tensorflow/tfjs";

import { default as Game2048Engine } from "./games/2048/GameEngine";
import { default as Connect4Engine } from "./games/connect4/GameEngine";

const marginSide = 3;
const buttonWidth = 100; //(width - marginSide) / 5 - marginSide;
const width = 6 * (buttonWidth + marginSide) + marginSide;
const height = 40;
const kMinMoveDelay = 30;

function GetTime() {
  return new Date().getTime();
}

function Softmax(arr) {
  return arr.map(function (value, index) {
    return (
      Math.exp(value) /
      arr
        .map(function (y /*value*/) {
          return Math.exp(y);
        })
        .reduce(function (a, b) {
          return a + b;
        })
    );
  });
}

function Onehot(arr) {
  let ret = new Array(arr.length).fill(0);
  ret[arr.indexOf(Math.max(...arr))] = 1;
  return ret;
}

export default class GameController extends Component {
  constructor(props) {
    super(props);
    // FIXME: condition on this.props.selectedGame
    this.gameEngine =
      this.props.selectedGame == "2048"
        ? new Game2048Engine()
        : new Connect4Engine();
    this.props.onGameInputAdapterDims(this.gameEngine.inputAdapter().dims);
    this.props.onGameNMoves(
      this.gameEngine.nMoves(this.gameEngine.state.player)
    );
    this.props.onNewGameState(this.gameEngine.state);
    console.log("controller constructed");
  }

  state = {
    gameStarted: false,
  };

  reset() {
    this.stopAutoGame();
    this.gameEngine.reset();
    this.props.onNewGameState(this.gameEngine.state);
  }

  moveWrapper() {
    if (!this.state.gameStarted) return;
    // FIXME: count how much time was spent for the move and only set timeout if move took lass time
    let start = GetTime();
    this.moveAsync((res) => {
      let finish = GetTime();
      //console.log(finish - start);
      if (res) {
        setTimeout(
          () => this.moveWrapper(),
          Math.max(0, kMinMoveDelay - (finish - start))
        );
      } else if (this.gameEngine.state.over) {
        this.setState({ gameStarted: false });
      }
    });
  }

  externalMove(move) {
    let player = this.gameEngine.state.player;
    let options = this.props.playerOptionsRef.current;
    if (options.state.playerTypes[player] != "HUMAN") {
      console.log("tried to make a move", move, "but it is not a humans turn.");
      return;
    }
    let start = GetTime();
    this.gameEngine.doMove(move);
    this.props.onGameNMoves(
      this.gameEngine.nMoves(this.gameEngine.state.player)
    );
    let finish = GetTime();
    this.props.onNewGameState(this.gameEngine.state);
    // FIXME: count how much time was spent for the move and only set timeout if move took lass time
    setTimeout(
      () => this.moveWrapper(),
      Math.max(0, kMinMoveDelay - (finish - start))
    );
  }

  evalCurrentState(cb) {
    let player = this.gameEngine.state.player;
    let options = this.props.playerOptionsRef.current;
    if (options.state.playerTypes[player] != "COMPUTER") {
      if (cb)
        cb(
          "To test model COMPUTER mode has to be selected for the active player."
        );
      return;
    }
    let inferenceOptions = this.props.inferenceRef.current;
    let modelName = inferenceOptions.state.selectedModels[player][player];
    let ml =
      modelName == "RANDOM"
        ? new RandomModelLoader(this.gameEngine.nMoves(player))
        : new TfModelLoader(
            this.props.modelingRef.current.loadedModels[modelName],
            -1 /* evaluate on every call */
          );
    let start = GetTime();
    let res = ml.getModelScoreBatch([
      { id: 0, input: this.gameEngine.inputAdapter() },
    ]);
    let finish = GetTime();
    let prettyState = JSON.stringify(this.gameEngine.state, undefined, 2);
    // everything inside nonnested arrays should be inlined for readability
    let regex = /(?<=\[[^\[\]]*)[ \n](?=[^\[\]]*\])/g;
    //console.log(prettyState.match(regex));
    prettyState = prettyState.replaceAll(regex, "");
    let message = (
      <div>
        <div style={{ marginBottom: 20 }}>
          {"Single inference took: " + (finish - start) + "ms."}
        </div>
        <div style={{ marginBottom: 20 }}>
          <div>{"State:"}</div>
          <div style={{ marginTop: -15, paddingLeft: 55 }}>
            <pre>{prettyState}</pre>
          </div>
        </div>
        <div>{"Result: "}</div>
        <div style={{ marginTop: -15, paddingLeft: 55 }}>
          <pre>{JSON.stringify(res[0].output, undefined, 2)}</pre>
        </div>
      </div>
    );
    if (cb) cb(message);
  }

  evalCurrentStateMcts(messageCb) {
    let cb = (failReason, move, simData) => {
      if (failReason) {
        if (messageCb) {
          messageCb(failReason);
          return;
        }
      }
      this.printSimData(simData, messageCb);
    };
    this.evalCurrentStateMctsInt(cb);
  }

  evalCurrentStateMctsInt(cb) {
    let player = this.gameEngine.state.player;
    let options = this.props.playerOptionsRef.current;
    if (options.state.playerTypes[player] != "COMPUTER") {
      cb(
        "To test model COMPUTER mode has to be selected for the active player."
      );
      return;
    }
    let inferenceOptions = this.props.inferenceRef.current;
    let modelNames = inferenceOptions.state.selectedModels;
    let mls = [];
    // NOTE: if no model is selected (or player is not a computer) the RANDOM model will be returned.
    console.log("using the following ml models:", JSON.stringify(modelNames));
    for (let i = 0; i < modelNames.length; i++) {
      let ml = [];
      for (let j = 0; j < modelNames[i].length; j++) {
        let name = modelNames[i][j];
        if (name == "RANDOM")
          ml.push(new RandomModelLoader(this.gameEngine.nMoves(j)));
        else {
          let model = this.props.modelingRef.current.loadedModels[name];
          if (!model) {
            console.log("model does not exist");
          }
          ml.push(new TfModelLoader(model, -1 /* evaluate on every call */));
        }
        mls.push(ml);
      }
    }
    this.startEvalCurrentStateMcts = GetTime();
    InferenceEngine.getMoveAsync(
      options.state.playerTypes[player],
      this.gameEngine.clone(),
      mls[player],
      this.props.inferenceProps,
      (move, simData) => {
        cb(null /*failReason*/, move, simData);
      }
    );
  }

  printSimData(simData, cb) {
    let player = this.gameEngine.state.player;
    let prettyState = JSON.stringify(this.gameEngine.state, undefined, 2);
    // everything inside nonnested arrays should be inlined for readability
    let regex = /(?<=\[[^\[\]]*)[ \n](?=[^\[\]]*\])/g;
    //console.log(prettyState.match(regex));
    prettyState = prettyState.replaceAll(regex, "");

    let policy = [];
    for (let i = 0; i < this.gameEngine.nMoves(player); i++) {
      policy.push(simData.childrenN[i] == undefined ? 0 : simData.childrenN[i]);
    }
    // NOTE: showing softmax since training is done through softmax
    policy = Softmax(policy);
    let data = [[simData.qs[player]], policy];

    let message = (
      <div>
        <div style={{ marginBottom: 20 }}>
          {"Monte Carlo simulation took: " +
            (GetTime() - this.startEvalCurrentStateMcts) +
            "ms."}
        </div>
        <div style={{ marginBottom: 20 }}>
          <div>{"State:"}</div>
          <div style={{ marginTop: -15, paddingLeft: 55 }}>
            <pre>{prettyState}</pre>
          </div>
        </div>
        <div>{"Result: "}</div>
        <div style={{ marginTop: -15, paddingLeft: 55 }}>
          <pre>{JSON.stringify(data, undefined, 2)}</pre>
        </div>
      </div>
    );
    if (cb) cb(message);
  }

  trainOnCurrentState(messageCb) {
    this.evalCurrentStateMctsInt((failReason, move, simData) => {
      if (failReason) {
        if (messageCb) {
          messageCb(failReason);
          return;
        }
      }
      let player = this.gameEngine.state.player;
      let options = this.props.playerOptionsRef.current;
      if (options.state.playerTypes[player] != "COMPUTER") {
        if (messageCb)
          messageCb(
            "To train model COMPUTER mode has to be selected for the active player."
          );
        return;
      }
      let inferenceOptions = this.props.inferenceRef.current;
      let modelName = inferenceOptions.state.selectedModels[player][player];
      if (modelName == "RANDOM") {
        if (messageCb)
          messageCb(
            "To train model RANDOM should NOT be selected for the active player."
          );
        return;
      }

      let model = this.props.modelingRef.current.loadedModels[modelName];

      let policy = [];
      for (let i = 0; i < this.gameEngine.nMoves(player); i++) {
        policy.push(
          simData.childrenN[i] == undefined ? 0 : simData.childrenN[i]
        );
      }
      // FIXME: which one is better?
      //policy = Softmax(policy);
      policy = Onehot(policy);

      let inputs = [this.gameEngine.inputAdapter().input];
      let values = [simData.qs[player]];
      let policies = [policy];

      //console.log(inputs);
      //console.log(values);
      //console.log(policies);

      let inputTensor = tf.tensor4d(inputs);
      let valueTensor = tf.tensor1d(values);
      let policyTensor = tf.tensor2d(policies);

      // FIXME: check if model has already been compiled
      // this.props.inferenceProps
      model.compile({
        optimizer:
          this.props.inferenceProps.optimizer == "adam"
            ? tf.train.adam(parseFloat(this.props.inferenceProps.learningRate))
            : tf.train.sgd(parseFloat(this.props.inferenceProps.learningRate)),
        loss: [
          "meanSquaredError" /* for value */,
          "categoricalCrossentropy" /* for policy*/, // NOTE: crossEntropy requires 1-hot labels
        ],
        metrics: ["accuracy"],
      });
      model
        .fit(inputTensor, [valueTensor, policyTensor], {
          epochs: parseFloat(this.props.inferenceProps.epochs),
          batchSize: 1,
          shuffle: true,
          //yieldEvery: "auto", // useful to free main thread
          //validationSplit: 0.1,
          //classWeight: [],
          //sampleWeight: same as input to increase weight of certain inputs (perhaps losing positions)
          callbacks: {
            onEpochEnd: (epoch, logs) => {
              //this.addToLog("Loss after epoch " + epoch + " is " + logs.loss);
              console.log("Loss after epoch", epoch, "is", logs.loss);
            },
          },
        })
        .then((info) => {
          console.log("Final loss", info.history.loss);
        });
    });
  }

  moveAsync(cb) {
    if (this.gameEngine.state.over) {
      console.log("over:", JSON.stringify(this.gameEngine.state));
      cb(false);
      return;
    }
    //console.log("Automove");
    // FIXME: At the moment gameRef does not change,
    // controller gets destroyed every time game changes
    if (!this.props.playerOptionsRef || !this.props.playerOptionsRef.current) {
      console.log("playerOptionsRef is not ready");
      cb(false);
      return;
    }
    let player = this.gameEngine.state.player;
    let options = this.props.playerOptionsRef.current;
    if (
      options.state.playerTypes[player] == "HUMAN" ||
      options.state.playerTypes[player] == "REMOTE"
    ) {
      console.log("human or remote move, ignoring automatic request");
      cb(false);
      return;
    }

    let inferenceOptions = this.props.inferenceRef.current;
    let modelNames = inferenceOptions.state.selectedModels;
    // FIXME: maybe optimize do not create model loaders on each move???
    // FIXME: add a check that model input shape and current game input shapes are the same?
    // BIGFIXME: the model loaders we pass down are per 2 players, but for top moves,
    // but inside the simulation for each player we are supposed to use a different model!????!
    let mls = [];
    // NOTE: if no model is selected (or player is not a computer) the RANDOM model will be returned.
    console.log("using the following ml models:", JSON.stringify(modelNames));
    for (let i = 0; i < modelNames.length; i++) {
      let ml = [];
      for (let j = 0; j < modelNames[i].length; j++) {
        let name = modelNames[i][j];
        if (name == "RANDOM")
          ml.push(new RandomModelLoader(this.gameEngine.nMoves(j)));
        else {
          let model = this.props.modelingRef.current.loadedModels[name];
          if (!model) {
            console.log("model does not exist");
          }
          ml.push(new TfModelLoader(model, -1 /* evaluate on every call */));
        }
        mls.push(ml);
      }
    }
    // clone the engine before passing into the inference engine to avoid state corruption
    InferenceEngine.getMoveAsync(
      options.state.playerTypes[player],
      this.gameEngine.clone(),
      mls[player],
      this.props.inferenceProps,
      (move) => {
        this.gameEngine.doMove(move);
        this.props.onGameNMoves(
          this.gameEngine.nMoves(this.gameEngine.state.player)
        );
        this.props.onNewGameState(this.gameEngine.state);
        cb(true);
      }
    );
  }

  startAutoGame() {
    if (this.state.gameStarted) return;
    // NOTE: this is needed because AutoMove will check state,
    // but state is being set asyncronously.
    this.state.gameStarted = true;
    console.log("StartAutogame");
    // make an auto move.
    this.moveWrapper();
  }

  stopAutoGame() {
    if (!this.state.gameStarted) return;
    console.log("StopAutogame");
    this.setState({ gameStarted: false });
  }

  render() {
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          top: -2, // to allign with parent outline
          height: height + 2 * marginSide,
          backgroundColor: this.state.gameStarted
            ? "rgb(189, 242, 166)"
            : "rgb(125, 125, 125)", // FIXME: change it to smth more meaningful
          overflowX: "auto",
          overflowY: "hidden",
          outline: "2px solid rgb(125, 125, 125)",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: width,
            left: "50%",
            top: 0,
            transform: "translateX(-50%)",
          }}
        >
          <div
            style={{
              marginTop: marginSide,
              marginBottom: marginSide,
              marginLeft: marginSide,
              marginRight: marginSide,
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <HighDiv
                text="RESET"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
                onClick={this.reset.bind(this)}
              />
              <HighDiv
                text="MOVE"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
                onClick={() => this.moveAsync(() => {})}
              />
              <HighDiv
                text="PLAY"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
                onClick={() => this.startAutoGame()}
              />
              <HighDiv
                text="STOP"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
                onClick={() => this.stopAutoGame()}
              />
              <HighDiv
                text="INFO"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
              />
              <HighDiv
                text="MULTI"
                style={{
                  width: buttonWidth,
                  height: height,
                  backgroundColor: "rgb(222, 222, 222)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "grab",
                  border: "2px solid rgb(125, 125, 125)",
                }}
                clickable={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
