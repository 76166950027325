import React, { Component } from "react";
import Graph from "./Graph";
import ModelInfo from "./ModelInfo";
import NodeInfo from "./NodeInfo";
import Consts from "./Consts";
import Styles from "./Styles";
import Board from "./Board";
import Utils from "./Utils";
import Palette, { palette } from "./Palette";
import Templates from "./Templates";
import ModelMenu from "./ModelMenu";
import InfoField from "./InfoField";

const outlineSize = 2;
const totalWidth = 450 + 4 * 30 + 3 * outlineSize;
const totalHeight = 810 + 1 * 30 + 7 * 30 + 6 * outlineSize + 3 * 2;

export default class MainModelUi extends Component {
  constructor(props) {
    super(props);
    this.inBoard = false;
    this.modelInfoRef = React.createRef();
    this.boardRef = React.createRef();
    this.graph = new Graph({ onUpdated: this.onGraphUpdated.bind(this) });
    this.loadedModels = {}; // name => tfmodel
  }

  componentDidMount() {
    this.reset();
  }

  reset() {
    this.graph.reset();
  }

  onGraphUpdated() {
    console.log("graph updated");
    this.setState({ graph: this.graph });
  }

  state = {
    locked: false,
    graph: { nodes: {}, edges: {} },
    activeModelName: "New Model",
  };

  addNodes(els, noUpdate) {
    let ret = {};
    for (let key in els) {
      let newkey = key;
      while (this.graph.nodes[newkey]) {
        newkey += "1";
      }
      //console.log(els[key].params);
      if (
        !this.graph.maybeAddNode(newkey, els[key].id, els[key].params, noUpdate)
      ) {
        console.log("can't add node", newkey);
        continue;
      }
      ret[key] = newkey;
      // FIXME: the node might get deleted if UPDATE is not called????
      this.boardRef.current.setNodeProps(newkey, els[key].ui);
    }
    return ret;
  }

  removeNode(id) {
    if (!id) return;
    this.graph.maybeRemoveNode(id);
  }

  removeArrow(id) {
    if (!id) return;
    this.graph.maybeRemoveEdge(id, false);
  }

  maybeAddArrow(start, end, noUpdate) {
    // FIXME: a little hard to drag, maybe press "ctrl" or "shift" when dragging
    // FIXME: copy node when shift and drag?
    // this function is caleld when arrows are dragged from pins (potentially)
    start = Utils.stripPinSuffix(start);
    end = Utils.stripPinSuffix(end);
    console.log("adding arrow", start, end);
    this.graph.maybeAddEdge(start, end, noUpdate);
  }

  loadModel(model) {
    if (this.state.locked) {
      this.displayLog(
        "Model is locked. To make changes unlock the model first."
      );
      console.log("model is locked");
      return;
    }
    this.reset();
    let nodes = {};
    let name = null;
    if (model.ui && model.ui.text) {
      name = model.ui.text;
      // for models loaded from the template
      this.setState({ activeModelName: model.ui.text });
    }
    for (let key in model.nodes) {
      //console.log(model.nodes[key]);
      nodes[key] = {
        id: model.nodes[key],
        params: { ...palette[model.nodes[key]].params },
        ui: {
          ...palette[model.nodes[key]].ui,
          x: 450 + 100, // this is to hide it outside of board in the beginning
          y: 0,
        },
      };
      if (model.params && model.params[key]) {
        for (let paramKey in model.params[key]) {
          // FIXME: if this is an array need to copy it
          nodes[key].params[paramKey] = model.params[key][paramKey];
          //console.log(key, model.params[key][paramKey]);
        }
      }
      //console.log(key, nodes[key]);
    }
    //console.log(JSON.stringify(nodes));
    this.addNodes(nodes, true /*noUpdate*/);
    for (let edge of model.edges) {
      this.maybeAddArrow(edge[0], edge[1], true /*noUpdate*/);
    }
    this.displayLog(
      "Loaded template model " + (name || this.activeModelName) + "."
    );
    setTimeout(() => {
      this.graph.recompute();
      this.onGraphUpdated();
      this.boardRef.current.autoPlace();
    }, 20);
  }

  onTfModel(tfmodel) {
    console.log(tfmodel);
    //this.tfModel = tfmodel;
    while (this.loadedModels[tfmodel.name]) {
      tfmodel.name += "1";
    }
    this.setState({ activeModelName: tfmodel.name });
    this.loadedModels[tfmodel.name] = tfmodel;
    this.props.onLoadedModelsChange(Object.keys(this.loadedModels));
    this.displayLog("Tf model [" + tfmodel.name + "] is ready.");
    // after new model has been loaded/built we lock it.
    // NOTE: settimeout since the model needs to be placed
    setTimeout(() => this.setState({ locked: true }), 100);
  }

  exportActiveTfModel() {
    if (
      !this.state.activeModelName ||
      !this.loadedModels[this.state.activeModelName]
    ) {
      console.log(
        "Model",
        this.state.activeModelName,
        "doesnot exist. Did you forget to build it first?"
      );
      this.props.displayLog(
        "Model " +
          this.state.activeModelName +
          " does not exist. Export aborted."
      );
      return;
    }
    console.log("saving model", this.state.activeModelName, "in tfjs format");
    this.loadedModels[this.state.activeModelName].save(
      "downloads://" + this.state.activeModelName
    );
    this.props.displayLog(
      "Exported model " + this.state.activeModelName + " to downlaods folder."
    );
  }

  updateActiveModelName(newName) {
    let oldName = this.state.activeModelName;
    while (this.loadedModels[newName]) {
      newName += "1";
    }
    this.setState({ activeModelName: newName });
    if (!this.loadedModels[oldName]) return;
    this.loadedModels[newName] = this.loadedModels[oldName];
    delete this.loadedModels[oldName];
    this.props.onLoadedModelsChange(Object.keys(this.loadedModels));
    this.displayLog("Model name set to " + newName);
  }

  displayLog(log) {
    this.setState({ infoText: log });
  }

  render() {
    return (
      <div style={Styles.dummyStyle}>
        <div
          style={{
            position: "absolute",
            width: totalWidth,
            height: 25,
            bottom: 45,
            left: "50%",
            top: 15,
            //top: "50%",
            zIndex: 2,
            transform: "translateX(-50%)",
            outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
          }}
        >
          }} >
          <InfoField text={this.state.infoText || "Logging enabled."} />
        </div>
        <div
          style={{
            position: "absolute",
            width: totalWidth,
            height: totalHeight,
            minHeight: totalHeight,
            left: "50%",
            top: 50,
            transform: "translateX(-50%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 450 / 2 + 60,
              height: 810 / 4,
              outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
            }}
          >
            <ModelInfo
              locked={this.state.locked}
              modelName={this.state.activeModelName}
              onModelNameUpdated={(newName) =>
                this.updateActiveModelName(newName)
              }
              graph={this.state.graph}
              ref={this.modelInfoRef}
              gameInputDims={this.props.gameInputDims}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: 450 / 2 + 60 + 3 * outlineSize,
              top: 0,
              width: 450 / 2 + 60,
              height: 810 / 4,
              outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
            }}
          >
            <NodeInfo
              graph={this.state.graph}
              locked={this.state.locked}
              id={this.state.selected ? this.state.selected : this.state.high}
              updatable={!!this.state.selected}
              onUpdate={(id, update) => this.graph.onNodeUpdate(id, update)}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 810 / 4 + 3 * outlineSize,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: 450 + 4 * 30 + 3 * outlineSize,
                height: 30 + 2 * 3,
                //outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
              }}
            >
              <ModelMenu
                name={this.state.activeModelName}
                locked={this.state.locked}
                setLocked={(locked) => {
                  this.displayLog(
                    "Model is " + (locked ? "locked." : "unlocked.")
                  );
                  this.setState({ locked });
                }}
                graph={this.state.graph}
                loadModel={this.loadModel.bind(this)}
                onTfModel={this.onTfModel.bind(this)}
                exportActiveTfModel={this.exportActiveTfModel.bind(this)}
                autoPlace={() => {
                  this.displayLog("Autoplaced the nodes on the board.");
                  this.boardRef.current.autoPlace();
                }}
                gameInputDims={this.props.gameInputDims}
                gameNMoves={this.props.gameNMoves}
                displayLog={this.displayLog.bind(this)}
              />
            </div>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 3 * outlineSize + 30 + 2 * 3,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 450 + 3 * outlineSize,
                  top: 0,
                  width: 4 * 30,
                  height: 810 / 2 - 30 / 2 - 2 * outlineSize,
                  outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
                }}
              >
                <Palette
                  onDragged={(el) => {
                    this.boardRef.current.onPaletteElDragged(el);
                  }}
                  onDropped={(el) => {
                    this.boardRef.current.onPaletteElDropped(el);
                  }}
                  onPaletteClick={(id) => {
                    this.boardRef.current.onPaletteClick(id);
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  height: 810,
                  width: 450,
                  left: 0,
                  top: 0,
                  outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
                }}
              >
                <Board
                  ref={this.boardRef}
                  locked={this.state.locked}
                  onRemoveNode={this.removeNode.bind(this)}
                  onRemoveArrow={this.removeArrow.bind(this)}
                  maybeAddArrow={this.maybeAddArrow.bind(this)}
                  maybeAddNodes={this.addNodes.bind(this)}
                  onSelected={(id) => {
                    this.setState({ selected: id });
                  }}
                  onHigh={(id) => {
                    this.setState({ high: id });
                  }}
                  graph={this.state.graph}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 450 + 3 * outlineSize,
                  top: 810 / 2 - 30 / 2 + outlineSize,
                  width: 4 * 30,
                  height: 810 / 2 + 30 / 2 - outlineSize,
                  outline: "solid " + outlineSize + "px rgb(125, 125, 125)",
                }}
              >
                <Templates loadModel={this.loadModel.bind(this)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { totalWidth, totalHeight };

//bird
//dog
//cat
