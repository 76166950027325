import React, { Component } from "react";
import HighDiv from "./modeling/HighDiv";
import Styles from "./modeling/Styles";
import ModelList from "./ModelList";

const marginSide = 10;
const buttonWidth = 200;
const nPlayers = 2;
const width = (nPlayers + 1) * buttonWidth; // use nPlayers
const height = 100;

const options = ["a", "b", "c"];

class PlayerOption extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          bottom: 0,
          width: 110,
          height: "100%",
          outline: "1px solid black",
          transition: "width 0.25s ease",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 20,
            height: 20,
            top: 7,
            left: 10,
            border: "1px solid black",
            borderRadius: 10,
          }}
        >
          {this.props.isActive ? (
            <div
              style={{
                position: "absolute",
                width: 5,
                height: 5,
                top: "50%",
                left: "50%",
                borderRadius: 4,
                transform: "translate(-50%,-50%)",
                backgroundColor: "black",
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <div
          style={{
            ...Styles.dummyStyle,
            display: "flex",
            //justifyContent: "center",
            //alignItems: "center",
            top: 9,
            left: 40,
          }}
        >
          {this.props.playerName}
        </div>
        <HighDiv
          text={this.props.type}
          style={{
            position: "absolute",
            left: 5,
            top: 37,
            width: 100,
            height: 40,
            backgroundColor: "rgb(222, 222, 222)",
            cursor: "grab",
          }}
          clickable={true}
          onClick={this.props.onClick}
        />
      </div>
    );
  }
}

export default class PlayerOptions extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    playerTypes: [],
    selectedModels: [],
  };
  componentDidMount() {
    let playerTypes = [];
    let selectedModels = [];
    for (let i = 0; i < this.props.nPlayers; i++) {
      playerTypes.push("COMPUTER");
      let models = [];
      for (let j = 0; j < this.props.nPlayers; j++) {
        models.push("RANDOM");
      }
      selectedModels.push(models);
    }
    this.setState({ playerTypes, selectedModels });
  }
  onClick(id) {
    this.state.playerTypes[id] =
      this.state.playerTypes[id] == "HUMAN"
        ? "MONKEY"
        : this.state.playerTypes[id] == "MONKEY"
        ? "COMPUTER"
        : this.state.playerTypes[id] == "COMPUTER"
        ? "REMOTE"
        : "HUMAN";
    this.setState({
      playerTypes: this.state.playerTypes,
    });
  }
  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "80%",
          left: "10%",
          bottom: -2, // to align with parent outline
          height: 84,
          display: "flex",
          justifyContent: "space-between",
          marginTop: marginSide,
          marginBottom: marginSide,
        }}
      >
        {this.state.playerTypes.map((type, id) => {
          return (
            <PlayerOption
              key={id}
              type={type}
              isActive={this.props.activePlayer == id}
              playerName={"Player " + id}
              onClick={() => this.onClick(id)}
            />
          );
        })}
      </div>
    );
  }
}

export { ModelList };
