import React, { Component } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-cpu";
//import "@tensorflow/tfjs-backend-wasm";
import ToggleSwitch from "./ToggleSwitch";
import HighDiv from "../modeling/HighDiv";
import InfoField from "../InfoField";
import PlayerModels from "./PlayerModels";

import { default as Game2048Engine } from "./../games/2048/GameEngine";
import { default as Connect4Engine } from "./../games/connect4/GameEngine";

const totalWidth = 570;
const totalHeight = 1050;

const updatableProps = {
  nSim: 50,
  randFrac: 0.1,
  maxTime: 0, // ms
  extraWinningScore: 0,
  epochs: 100,
  learningRate: 0.001,
  optimizer: "adam",
};

// TODO: per player, or apply the same for both players!!!??
// TODO: set tf backend

export default class InferenceUi extends Component {
  constructor(props) {
    super(props);
    this.engine =
      this.props.selectedGame == "2048"
        ? new Game2048Engine()
        : new Connect4Engine();
    this.props.onUpdateProps(updatableProps);
  }

  componentDidMount() {
    let playerTypes = [];
    let selectedModels = [];
    for (let i = 0; i < this.engine.nPlayers(); i++) {
      playerTypes.push("COMPUTER");
      let models = [];
      for (let j = 0; j < this.engine.nPlayers(); j++) {
        models.push("RANDOM");
      }
      selectedModels.push(models);
    }
    this.setState({
      selectedModels,
      playerTypes,
    });
    this.onBackendToggled(this.state.tfBackend);
    // FIXME: any race conditions?
    // NOTE: if you don't copy the state props will be updated together with the state
    this.setState({ update: { ...updatableProps } });
  }

  onBackendToggled(val) {
    this.setState({ tfBackend: val });
    tf.setBackend(val);
  }

  state = {
    update: {},
    tfBackend: "webgl",
    playerTypes: [],
    selectedModels: [],
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: totalWidth,
          height: "100%", //totalHeight,
          left: "50%",
          top: 0,
          //top: "50%",
          transform: "translateX(-50%)",
          //overflow: "hidden",
          //backgroundColor: "yellow",
        }}
      >
        <div
          style={{
            bottom: 45,
            position: "absolute",
            width: "100%",
            left: 0,
            height: 700,
          }}
        >
          <InfoField
            text={this.state.infoText || ""}
            onClear={() => {
              this.setState({ infoText: [] });
            }}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: 200,
            height: 50,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            //border: "1px solid black",
            //display: "flex",
            //justifyContent: "center",
            //alignItems: "center",
          }}
        >
          {"TF BACKEND:"}
          <ToggleSwitch
            values={["webgl", "cpu" /*wasm*/]}
            selected={this.state.tfBackend}
            onSwitchValue={(val) => {
              this.onBackendToggled(val);
            }}
          />
        </div>
        <PlayerModels
          onUpdate={() => {
            this.setState({ selectedModels: this.state.selectedModels });
          }}
          playerTypes={this.state.playerTypes}
          modelList={this.props.modelList}
          selectedModels={this.state.selectedModels}
        />
        <HighDiv
          text="TEST MODEL"
          style={{
            marginTop: 20,
            width: 200,
            height: 50,
            backgroundColor: "rgb(200, 200, 200)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "grab",
            marginBottom: 5,
          }}
          clickable={true}
          onClick={() =>
            this.props.onTest((message) => {
              this.setState({ infoText: message });
            })
          }
        />
        <HighDiv
          text="TEST MCTS"
          style={{
            marginTop: 0,
            width: 200,
            height: 50,
            backgroundColor: "rgb(200, 200, 200)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "grab",
            marginBottom: 5,
          }}
          clickable={true}
          onClick={() =>
            this.props.onTestMcts((message) => {
              this.setState({ infoText: message });
            })
          }
        />
        <HighDiv
          text="TRAIN MODEL"
          style={{
            marginTop: 0,
            width: 200,
            height: 50,
            backgroundColor: "rgb(200, 200, 200)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "grab",
            marginBottom: 20,
          }}
          clickable={true}
          onClick={() =>
            this.props.onTrain((message) => {
              this.setState({ infoText: message });
            })
          }
        />
        {Object.keys(this.props.updatableProps).map((key) => {
          //let prop = updatableProps[key];
          return (
            <div
              key={key}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {key + ": " + this.props.updatableProps[key]}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // NOTE: if you don't copy the state props will be updated together with the state
                  this.props.onUpdateProps({ ...this.state.update });
                }}
              >
                <input
                  type="text"
                  name="name"
                  value={this.state.update[key]}
                  onChange={(event) => {
                    this.state.update[key] = event.target.value;
                    this.setState({ update: this.state.update });
                  }}
                  style={{ width: 50, marginRight: 5 }}
                />
                <button>SET</button>
              </form>
            </div>
          );
        })}
      </div>
    );
  }
}
