import React, { Component } from "react";
import Consts from "./Consts";
import Styles from "./Styles";
import Xarrow from "react-xarrows";
import Utils from "./Utils";
import RndWrapper from "./RndWrapper";
import { palette } from "./Palette";
import HighDiv from "./HighDiv";

export default class Board extends Component {
  constructor(props) {
    super(props);
    this.inBoard = false;
    this.initGridLines();
  }

  initGridLines() {
    this.gridLines = [];
    //NOTE: we intentionally paint more lines just in case board wil lbe resized later
    // hence factor Consts.boardW * 2.
    // vertical lines
    for (let i = 0; i < Consts.boardW * 2; i++) {
      this.gridLines.push({
        lb: { x: i * Consts.cellSize - 1, y: Consts.cellSize * Consts.boardHH },
        rt: { x: i * Consts.cellSize + 1, y: 0 },
      });
    }
    // horizontal lines
    for (let i = 0; i < Consts.boardHH; i++) {
      this.gridLines.push({
        lb: { x: 0, y: i * Consts.cellSize + 1 },
        rt: {
          x: Consts.cellSize * Consts.boardW * 2,
          y: i * Consts.cellSize - 1,
        },
      });
    }
  }

  handleKey(e) {
    // FIXME: we should disable this when modeling tab is not active!
    if (this.props.locked) {
      console.log("model is locked");
      return;
    }
    if (e.keyCode == 46) {
      this.props.onRemoveNode(this.state.selected);
      this.props.onRemoveArrow(this.state.selArrow);
    }
  }

  componentDidMount() {
    //console.log(document.getElementById("node1"));
    document.addEventListener("keydown", this.handleKey.bind(this), false);
    document.addEventListener(
      "mousedown",
      this.handleMouseDown.bind(this),
      false
    );
    document.addEventListener("mouseup", this.handleMouseUp.bind(this), false);
  }

  handleMouseDown(e) {
    if (this.props.locked) {
      //console.log("model is locked");
      return;
    }
    this.maybeStartCursorArrow(e);
  }

  handleMouseUp(e) {
    if (this.props.locked) {
      //console.log("model is locked");
      return;
    }
    this.maybeFinishNewArrow();
  }

  maybeFinishNewArrow() {
    if (!this.newArrowStart) return;
    let end = this.highPin;
    if (!end) end = this.highNode;
    //console.log(end);
    if (!end || end == this.newArrowStart) {
      this.newArrowStart = undefined;
    } else {
      this.props.maybeAddArrow(this.newArrowStart, end);
      this.newArrowStart = undefined;
    }
    this.deactivateCursorArrow();
  }

  maybeStartCursorArrow(e) {
    if (this.highPin) {
      this.newArrowStart = this.highPin;
      this.activateCursorArrow(this.highPin);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey.bind(this), false);
    document.removeEventListener(
      "mousedown",
      this.handleMouseDown.bind(this),
      false
    );
    document.removeEventListener(
      "mouseup",
      this.handleMouseUp.bind(this),
      false
    );
  }

  static getDerivedStateFromProps(props, state) {
    let nodes = {};
    for (let key in state.nodes) {
      // keep old nodes, but remove the nodes that are not there
      if (props.graph.nodes[key]) {
        nodes[key] = state.nodes[key];
      }
    }
    return { nodes };
  }

  setNodeProps(id, props) {
    // NOTE: if node does not exist it wil lbe created
    this.state.nodes[id] = props;
  }

  alignToGrid(pos) {
    return {
      x: Math.floor(pos.x / Consts.cellSize) * Consts.cellSize,
      y: Math.floor(pos.y / Consts.cellSize) * Consts.cellSize,
    };
  }

  moveNode(id, pos) {
    if (this.props.locked) {
      console.log("model is locked");
      return;
    }
    //console.log("moveNode");
    this.state.nodes[id].x = pos.x;
    this.state.nodes[id].y = pos.y;
    this.updateBoard();
  }

  onBoardEnter(event) {
    if (this.props.locked) {
      //console.log("model is locked");
      return;
    }
    let currentTargetRect = event.currentTarget.getBoundingClientRect();
    let eventOffsetX = event.pageX - currentTargetRect.left;
    let eventOffsetY = event.pageY - currentTargetRect.top;
    let scrollTop = event.currentTarget.scrollTop;
    let docScrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;

    // FIXME: condition this state based if board is locked or not
    this.setState({
      cursor: { x: eventOffsetX, y: eventOffsetY + scrollTop - docScrollTop },
    });

    if (this.state.paletteElDragged) {
      //console.log(eventOffsetX, eventOffsetY);
      if (!this.inBoard) {
        // we jsut entered the board, create a new element
        this.inBoard = true;
        this.addPaletteNode({
          ...this.alignToGrid({
            x: eventOffsetX,
            y: eventOffsetY + scrollTop - docScrollTop,
          }),
        });
      } else {
        this.moveNode(
          this.state.paletteElDragged,
          this.alignToGrid({
            x: eventOffsetX,
            y: eventOffsetY + scrollTop - docScrollTop,
          })
        );
      }
    }
  }

  onBoardLeave() {
    if (this.props.locked) {
      return;
    }
    this.inBoard = false;
    if (this.state.paletteElDragged) {
      this.props.onRemoveNode(this.state.paletteElDragged);
    }
  }

  state = {
    selected: undefined,
    selArrow: undefined,
    nodes: {}, // node.ui from palette
    boardX: 0,
    boardY: 0,
    cursor: { x: 0, y: 0 },
  };

  activateCursorArrow(id) {
    this.state.cursorArrow = { start: id, end: "cursor" };
    this.updateBoard();
  }

  deactivateCursorArrow() {
    this.state.cursorArrow = undefined;
    this.updateBoard();
  }

  onPaletteElDragged(el) {
    if (this.props.locked) {
      //console.log("model is locked");
      return;
    }
    if (!this.state.paletteElDragged) this.setState({ paletteElDragged: el });
  }

  onPaletteElDropped(el) {
    if (this.props.locked) {
      console.log("model is locked");
      return;
    }
    if (
      this.state.paletteElDragged &&
      this.state.nodes[this.state.paletteElDragged]
    )
      this.state.nodes[this.state.paletteElDragged].temp = false;
    this.setState({ paletteElDragged: undefined });
    console.log("paletteid: ", this.state.paletteElDragged);
  }

  addPaletteNode(pos) {
    //console.log("here", this.state.paletteElDragged);
    // FIXME: this is a hack to get the original id of the layer in the palette since its name can change
    let paletteNode = palette[this.state.paletteElDragged.substr(0, 2)];
    let nodes = {};
    nodes[paletteNode.id] = {
      ...paletteNode,
      ui: { ...paletteNode.ui, ...pos, temp: true },
    };
    let newPaletteId = this.props.maybeAddNodes(nodes)[paletteNode.id];
    //console.log(this.state.paletteElDragged, newPaletteId);
    this.setState({ paletteElDragged: newPaletteId });
  }

  autoPlace() {
    if (this.props.locked) {
      console.log("model is locked");
      return;
    }
    if (!this.props.graph || !this.props.graph.nodes) return;
    // takes the graph and automatically places
    // the nodes on the board (based on the node level)
    console.log("autoplace is called", this.props.graph.height);
    // TODO: remove all nodes that are not "computed"? Or mark them differently somehow
    // collect all the nodes per given level
    // place nodes inside each level
    // TODO: maybe animate for more visual effect?
    let levels = []; // level 0 is outputs, level "height-1" is largest input
    // NOTE: since we place all the inputs on the same level, we artificially place it the largest possible level
    for (let i = 0; i < this.props.graph.height; i++) levels.push([]);
    for (let key in this.props.graph.nodes) {
      let node = this.props.graph.nodes[key];
      if (!node.computed) {
        this.state.nodes[key].x = 1 * Consts.cellSize;
        this.state.nodes[key].y = 4 * Consts.cellSize;
        continue;
      }
      if (node.type == "in") levels[levels.length - 1].push(key);
      else {
        if (node.type == "ou" && node.level != 0) {
          console.log(
            "WARNING!!!! node ",
            node.id,
            "is an output but has nonzero level",
            node.level
          );
        }
        // NOTE: outputs not always have level 0!
        // if output is an input to another output then this will not work.
        // FIXME: maybe enforce that each output can never be in fanin of another output
        levels[node.level].push(key);
      }
    }

    let curH = 0;
    let defaultH = Math.floor(
      (Consts.boardH - 4) / (this.props.graph.height - 2)
    );
    let rem = Consts.boardH - 4 - defaultH * (this.props.graph.height - 2);
    for (let i = levels.length - 1; i >= 0; i--) {
      let levelH = 2;
      // for inputs and outputs we reserve the single cell layers in the top and bottom
      if (i != 0 && i + 1 != levels.length) {
        // worst case 4 cells per level
        // FIXME: go over the ndoes and compute height based on the node heights
        // if there are no blocks in the level, then heihgt could be 2...
        if (defaultH < 6) {
          levelH = 6;
        } else {
          levelH = defaultH + (i <= rem ? 1 : 0);
        }
      }
      // calculate spacing between nodes within the same level
      // FIXME: randomize position of the node if there is a single node per level,
      // since arrows get a little overaligned
      let horSpacing = 1; // FIXME: Set it to 1 for now
      let totalW = 0; // without cellSize
      for (let key of levels[i]) {
        totalW +=
          Math.floor(this.state.nodes[key].width / Consts.cellSize) +
          horSpacing;
      }
      // subtract the last one
      totalW -= horSpacing;
      let curW = Consts.cellSize * Math.floor(Consts.boardW / 2 - totalW / 2);
      for (let key of levels[i]) {
        let stateNode = this.state.nodes[key];
        stateNode.x = curW;
        // FIXME: what if block height is higher than layer size?
        stateNode.y = Math.max(
          Math.floor(
            curH + levelH / 2 - stateNode.height / 2 / Consts.cellSize
          ) * Consts.cellSize,
          0
        );
        curW += stateNode.width + horSpacing * Consts.cellSize;
        //console.log(key, curW, curH, JSON.stringify(stateNode));
      }
      //console.log(curH);
      curH += levelH;
    }
    this.updateBoard();
  }

  updateBoard() {
    if (this.props.locked) {
      //console.log("model is locked");
      return;
    }
    // FIXME: if board is locked we don't need to update????
    // FIXME: this is inefficient??
    this.setState(this.state);
    //console.log("update board called!");
  }

  onPaletteClick(id) {
    if (this.props.locked) {
      console.log("model is locked");
      return;
    }
    console.log("clicked", id);
    let paletteNode = palette[id];
    let nodes = {};
    let pos = {
      x: (Consts.boardW - 1) * Consts.cellSize - paletteNode.ui.width,
      y: 1 * Consts.cellSize,
    };
    nodes[paletteNode.id] = {
      ...paletteNode,
      ui: { ...paletteNode.ui, ...pos, temp: false },
    };
    this.props.maybeAddNodes(nodes);
    this.updateBoard();
  }

  render() {
    //console.log("render");
    //console.log(this.state.cursor);
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          overflow: "hidden",
        }}
      >
        <div
          id="board"
          style={{
            ...Styles.dummyStyle,
            overflowX: "hidden",
            overflowY: "scroll",
            paddingRight: 20,
          }}
          onMouseEnter={this.onBoardEnter.bind(this)}
          onMouseLeave={this.onBoardLeave.bind(this)}
          onMouseMove={this.onBoardEnter.bind(this)}
          onClick={() => {
            this.setState({ selected: undefined });
            this.setState({ selArrow: undefined });
            this.props.onSelected(undefined);
          }}
        >
          <div
            style={{
              position: "absolute", // NOTE: this does not work with TransformWrapper
              height: Consts.boardHH * Consts.cellSize,
              width: Consts.boardW * Consts.cellSize,
              left: 0,
              top: 0,
              backgroundColor: "rgb(243,243,243)",
            }}
          >
            {this.gridLines.map((line, id) => {
              if (id % 5 == 0) return null;
              return (
                <div
                  key={"gridline" + id}
                  style={{
                    position: "absolute",
                    left: line.lb.x,
                    top: line.rt.y,
                    width: Math.abs(line.lb.x - line.rt.x),
                    height: Math.abs(line.lb.y - line.rt.y),
                    backgroundColor: "rgb(233, 233, 233)",
                  }}
                />
              );
            })}
            {this.gridLines.map((line, id) => {
              if (id % 5 != 0) return null;
              return (
                <div
                  key={"gridline" + id}
                  style={{
                    position: "absolute",
                    left: line.lb.x,
                    top: line.rt.y,
                    width: Math.abs(line.lb.x - line.rt.x),
                    height: Math.abs(line.lb.y - line.rt.y),
                    backgroundColor: "rgb(209, 209, 209)",
                  }}
                />
              );
            })}
            {Object.keys(this.props.graph.edges).map((key) => {
              return (
                <div
                  key={key}
                  style={{ ...Styles.dummyStyle, pointerEvents: "none" }}
                  id={"arrow_wrapper_" + key}
                >
                  <Xarrow
                    id={key}
                    key={key}
                    start={this.props.graph.edges[key].start}
                    end={this.props.graph.edges[key].end}
                    path={"smooth"}
                    passProps={{
                      onClick: () => {
                        setTimeout(() => {
                          console.log("clicked", key);
                          this.setState({ selArrow: key });
                        }, 10);
                      },
                    }}
                    color={
                      this.state.selArrow == key
                        ? "rgb(235, 77, 77)"
                        : "rgb(103, 151, 222)"
                    }
                    strokeWidth={this.state.selArrow == key ? 4 : 3}
                  />
                </div>
              );
            })}
            {this.state.cursorArrow ? (
              <div
                id={this.state.cursorArrow.end}
                style={{
                  position: "absolute",
                  width: 10,
                  height: 10,
                  backgroundColor: "purple",
                  top: this.state.cursor.y,
                  left: this.state.cursor.x,
                }}
              />
            ) : (
              <div />
            )}
            {this.state.cursorArrow ? (
              <Xarrow
                start={this.state.cursorArrow.start}
                end={this.state.cursorArrow.end}
                color={"rgb(103, 151, 222)"}
                path={"smooth" /*straight or grid*/}
                strokeWidth={3}
              />
            ) : (
              <div />
            )}
            {Object.keys(this.state.nodes).map((key) => {
              return (
                <RndWrapper
                  id={key}
                  key={key}
                  isSelected={this.state.selected == key}
                  onClick={(id) => {
                    if (this.state.nodes[key].temp || this.state.dragged)
                      return;
                    if (Utils.isPinId(id)) {
                      return;
                    }
                    // NOTE: timeout is needed caluse we cleanup selected on board click
                    setTimeout(() => {
                      this.props.onSelected(key);
                      this.setState({ selected: key });
                    }, 25);
                  }}
                  onMouseEnter={(id) => {
                    if (this.state.nodes[key].temp || this.state.dragged)
                      return;
                    if (Utils.isPinId(id)) {
                      //console.log(id);
                      this.highPin = id;
                      // TODO: end arrow!!!!
                      // TODO: optimize setting state/copying?
                      this.state.nodes[key].dragging = false;
                      this.state.nodes[key].resizing = false;
                      //this.setState({ nodes: this.state.nodes });
                      this.updateBoard();
                    } else {
                      this.highNode = id;
                      this.props.onHigh(id);
                    }
                  }}
                  onMouseLeave={(id) => {
                    if (this.state.nodes[key].temp || this.state.dragged)
                      return;
                    if (Utils.isPinId(id)) {
                      this.highPin = undefined;
                      // NOTE: we do not reset state here.
                      // TODO: optimize setting state/copying?
                      this.state.nodes[key].dragging = true;
                      this.state.nodes[key].resizing = true;
                      //this.setState({ nodes: this.state.nodes });
                      this.updateBoard();
                    } else {
                      this.highNode = undefined;
                      this.props.onHigh(undefined);
                    }
                  }}
                  state={this.state.nodes[key]}
                  locked={this.props.locked}
                  setState={(state) => {
                    if (this.props.locked) {
                      console.log("model is locked");
                      return;
                    }
                    //let copy = { ...this.state.nodes };
                    //this.setState({ dragged: state.dragged });
                    //if (state && state.x) copy[key] = state;
                    //this.setState({ nodes: copy });
                    if (state && state.x) {
                      this.state.nodes[key] = state;
                      this.updateBoard();
                    }
                  }}
                  color={this.state.nodes[key].color}
                  info={
                    this.props.graph.nodes[key]
                      ? this.props.graph.nodes[key].info
                      : undefined
                  }
                />
              );
            })}
          </div>
        </div>
        {this.props.locked ? (
          <div
            style={{
              position: "absolute", // NOTE: this does not work with TransformWrapper
              height: 2 * Consts.cellSize,
              width: 2 * Consts.cellSize,
              left: (2 * Consts.cellSize) / 2 - 7,
              top: (2 * Consts.cellSize) / 2 - 9,
              backgroundImage: "url('modeling/lock.svg')",
              backgroundSize: "contain",
              opacity: 0.6,
            }}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            boxShadow:
              "inset 2px 2px 2px rgba(65, 65, 65, 0.21),inset -2px -2px 2px rgba(65, 65, 65, 0.21)",
          }}
        />
      </div>
    );
  }
}
