import React, { Component } from "react";
import ModelList from "./../ModelList";

export default class PlayerModels extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: 220,
          height: 80,
          top: 20,
          right: 0,
          display: "flex",
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: "space-between",
          backgroundColor: "rgb(205, 205, 205)",
        }}
      >
        {this.props.playerTypes.map((type, id) => {
          return (
            <div
              style={{ position: "relative", width: 120, height: 20 }}
              key={id}
            >
              <div
                style={{
                  position: "relative",
                  left: 10,
                  width: 80,
                  height: 20,
                }}
              >
                {"Player " + id}
              </div>
              <div style={{ position: "relative", width: 120, height: 20 }}>
                <ModelList
                  modelListProps={{
                    playerTypes: this.props.playerTypes,
                    models: this.props.modelList,
                    selected: this.props.selectedModels[id],
                    setSelected: (modelId, update) => {
                      //console.log(update, id);
                      // TODO: check if we can mutate state like this
                      this.props.selectedModels[id][modelId] = update;
                      this.props.onUpdate();
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
