import Consts from "./Consts";

function InitBoard() {
  let b = [];
  for (let i = 0; i < Consts.boardDim.w; i++) {
    b.push([]);
  }
  return b;
}

// returns {x,y}
// FIXME: no need for y!!!!
function GetAvailableMoves(board) {
  let moves = [];
  for (let i = 0; i < Consts.boardDim.w; i++)
    if (CanExecuteMove(board, i)) moves.push(i);
  return moves;
}

function CanExecuteMove(board, x) {
  return board[x].length < Consts.boardDim.h;
}

function ExecuteMove(board, x, player) {
  if (!CanExecuteMove(board, x)) return false;
  board[x].push(player);
  return true;
}

function CheckHorizontal(board, lastCoin) {
  let left = 1;
  let right = 1;
  while (true) {
    if (
      lastCoin.x - left >= 0 &&
      board[lastCoin.x - left].length > lastCoin.y &&
      board[lastCoin.x - left][lastCoin.y] == board[lastCoin.x][lastCoin.y]
    ) {
      left++;
    } else {
      break;
    }
  }
  while (true) {
    if (
      lastCoin.x + right < Consts.boardDim.w &&
      board[lastCoin.x + right].length > lastCoin.y &&
      board[lastCoin.x + right][lastCoin.y] == board[lastCoin.x][lastCoin.y]
    ) {
      right++;
    } else {
      break;
    }
  }
  return left + right - 1 >= 4;
}

function CheckDiagRightTop(board, lastCoin) {
  let left = 1;
  let right = 1;
  while (true) {
    if (
      lastCoin.x - left >= 0 &&
      lastCoin.y - left >= 0 &&
      board[lastCoin.x - left].length > lastCoin.y - left &&
      board[lastCoin.x - left][lastCoin.y - left] ==
        board[lastCoin.x][lastCoin.y]
    ) {
      left++;
    } else {
      break;
    }
  }
  while (true) {
    if (
      lastCoin.x + right < Consts.boardDim.w &&
      lastCoin.y + right < board[lastCoin.x + right].length &&
      board[lastCoin.x + right].length > lastCoin.y + right &&
      board[lastCoin.x + right][lastCoin.y + right] ==
        board[lastCoin.x][lastCoin.y]
    ) {
      right++;
    } else {
      break;
    }
  }
  return left + right - 1 >= 4;
}

function CheckDiagLeftTop(board, lastCoin) {
  let left = 1;
  let right = 1;
  while (true) {
    if (
      lastCoin.x - left >= 0 &&
      lastCoin.y + left < board[lastCoin.x - left].length &&
      board[lastCoin.x - left].length > lastCoin.y + left &&
      board[lastCoin.x - left][lastCoin.y + left] ==
        board[lastCoin.x][lastCoin.y]
    ) {
      left++;
    } else {
      break;
    }
  }
  while (true) {
    if (
      lastCoin.x + right < Consts.boardDim.w &&
      lastCoin.y - right >= 0 &&
      board[lastCoin.x + right].length > lastCoin.y - right &&
      board[lastCoin.x + right][lastCoin.y - right] ==
        board[lastCoin.x][lastCoin.y]
    ) {
      right++;
    } else {
      break;
    }
  }
  return left + right - 1 >= 4;
}

function CheckVertical(board, lastCoin) {
  let h = 1;
  while (true) {
    if (
      lastCoin.y - h >= 0 &&
      board[lastCoin.x][lastCoin.y - h] == board[lastCoin.x][lastCoin.y]
    ) {
      h++;
    } else {
      break;
    }
  }
  return h >= 4;
}

function CheckDraw(board) {
  for (let x of board) if (x.length < Consts.boardDim.h) return false;
  return true;
}

function CheckGameOver(board, lastMove) {
  let lastCoin = { x: lastMove, y: board[lastMove].length - 1 };
  // returns 2 if its draw....
  if (
    CheckHorizontal(board, lastCoin) ||
    CheckVertical(board, lastCoin) ||
    CheckDiagLeftTop(board, lastCoin) ||
    CheckDiagRightTop(board, lastCoin)
  ) {
    return board[lastCoin.x][lastCoin.y] == 0
      ? { over: true, winner: "first" }
      : { over: true, winner: "second" };
  }
  if (CheckDraw(board)) return { over: true, winner: "draw" };
  return { over: false, winner: "none" };
}

const Rules = {
  initBoard: InitBoard,
  getAvailableMoves: GetAvailableMoves,
  executeMove: ExecuteMove,
  checkGameOver: CheckGameOver,
};

export default Rules;
