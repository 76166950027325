const Styles = {
  dummyStyle: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  dummyRightStyle: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
};

export default Styles;
