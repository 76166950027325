import MCTSAsync from "./../MCTSAsync";

function GetMoveAsync(type, engine, ml, extraProps, cb) {
  return type == "MONKEY"
    ? GetRandomMoveAsync(engine, cb)
    : type == "COMPUTER"
    ? GetMonteCarloMoveAsync(engine, ml, extraProps, cb)
    : undefined;
}

function GetMonteCarloMoveAsync(engine, ml, extraProps, cb) {
  let mcts = new MCTSAsync(ml);
  mcts.getBestMoveAsync(engine, extraProps, cb);
}

// NOTE: for random move resources don't matter
function GetRandomMove(engine) {
  let moves = engine.getAvailableMoves();
  let rnd = Math.floor(Math.random() * moves.length);
  return moves[rnd];
}

function GetRandomMoveAsync(engine, cb) {
  let moves = engine.getAvailableMoves();
  let rnd = Math.floor(Math.random() * moves.length);
  cb(moves[rnd]);
}

const InferenceEngine = {
  getMoveAsync: GetMoveAsync,
};

export default InferenceEngine;
