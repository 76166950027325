import React, { Component } from "react";
import Styles from "./Styles";

function ValueFromString(str) {
  if (typeof str != "string") return str;
  let arr = str.split(",");
  if (arr.length == 1) return parseInt(str);
  return arr.map((el) => {
    return parseInt(el);
  });
}

export default class NodeInfo extends Component {
  state = {
    id: undefined,
    update: {},
  };

  static getDerivedStateFromProps(props, state) {
    let node =
      props.graph && props.id ? props.graph.nodes[props.id] : undefined;
    if (!node) return null;

    if (state.id != props.id) {
      return { id: props.id, update: { ...node.params } };
    }
    return null;
  }

  // props has graph
  render() {
    let node =
      this.props.graph && this.props.id
        ? this.props.graph.nodes[this.props.id]
        : undefined;
    //console.log(node);
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          backgroundColor: "rgb(240,240,240)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -1,
            height: 30,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(245, 245, 245)",
            backgroundColor: "rgb(125, 125, 125)",
            fontWeight: "bold",
            userSelect: "none", // for unselectable text
          }}
        >
          NODE INFO
        </div>
        {!node ? (
          <div />
        ) : (
          <div
            style={{
              position: "absolute",
              top: 30,
              marginTop: 15,
              marginBottom: 15,
              //paddingLeft: 15,
              marginLeft: 15,
              marginRight: 15,
              overflowX: "hidden",
              height: "70%",
              width: "89%",
              overflowY: this.props.updatable ? "auto" : "hidden",
            }}
          >
            <div>
              <div>
                {"Input shape: " + (JSON.stringify(node.info.inShape) || "[]")}
              </div>
              <div>
                {"Output shape: " +
                  (JSON.stringify(node.info.outShape) || "[]")}
              </div>
              <div>{"Weights: " + (node.info.w || 0)}</div>
              <div>{"Computations: " + (node.info.c || 0)}</div>
            </div>
            <div
              style={{
                width: "100%",
                height: 2,
                backgroundColor: "grey",
                marginTop: 5,
                marginBottom: 5,
              }}
            />
            <div>
              {Object.keys(node.params).map((key) => {
                return (
                  <div
                    key={key}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{key + ": " + JSON.stringify(node.params[key])}</div>
                    {this.props.updatable && !this.props.locked ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          let obj = {};
                          // FIXME: what other keys should be strings?
                          if (key == "activation") {
                            obj[key] = this.state.update[key];
                          } else {
                            obj[key] = ValueFromString(this.state.update[key]);
                          }
                          this.props.onUpdate(this.props.id, obj);
                        }}
                      >
                        <input
                          type="text"
                          name="name"
                          value={this.state.update[key]}
                          onChange={(event) => {
                            this.state.update[key] = event.target.value;
                            this.setState(this.state);
                          }}
                          style={{ width: 50, marginRight: 5 }}
                        />
                        <button>SET</button>
                      </form>
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
