import React, { Component } from "react";
import Styles from "./modeling/Styles";

const games = [{ name: "Connect4" }, { name: "2048" }];

class GameList extends Component {
  componentDidMount() {
    this.setState({ selected: games[0].name });
    this.props.onGameChanged(games[0].name);
  }
  state = {
    high: {},
    selected: undefined,
  };
  render() {
    return (
      <div
        style={{ ...Styles.dummyStyle }}
        onClick={() => {
          this.props.onGameChanged(undefined);
          this.setState({ selected: undefined });
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 2,
            left: 0,
            top: 0,
            backgroundColor: "rgb(113, 113, 113)",
          }}
        />
        {games.map((game) => {
          return (
            <div
              key={game.name}
              style={{
                position: "relative",
                width: "100%",
                height: 50,
                left: 0,
                top: 0,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%", //""96%",
                  height: 50, //46,
                  left: 0, //"2%",
                  top: 0, //2, //3,
                  marginTop: 0,
                  backgroundColor:
                    this.state.selected == game.name
                      ? "rgb(255, 245, 224)"
                      : this.state.high[game.name]
                      ? "rgb(217, 217, 217)"
                      : undefined,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                  //borderRadius: 5,
                }}
                onMouseEnter={() => {
                  this.state.high[game.name] = true;
                  this.setState(this.state);
                }}
                onMouseLeave={() => {
                  this.state.high[game.name] = false;
                  this.setState(this.state);
                }}
                onClick={() => {
                  // TIMEOUT is because we clear game id when clicked on the parent div
                  setTimeout(() => {
                    this.props.onGameChanged(game.name);
                    this.setState({ selected: game.name });
                  }, 20);
                }}
              >
                {game.name}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default class Games extends Component {
  state = { opened: false };
  componentDidMount() {
    this.onMenuClick();
  }
  onMenuClick() {
    this.props.onHide(this.state.opened);
    this.setState({ opened: !this.state.opened });
  }
  render() {
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          width: this.props.width,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            height: "100%",
            left: -this.props.width - 2,
            transform: this.state.opened
              ? "translateX(" + (this.props.width + 2) + "px)"
              : undefined,
            transition: "transform 0.5s ease 0s",
            //borderLeft: "2px rgb(113, 113, 113) solid",
            borderRight: "2px rgb(113, 113, 113) solid",
            zIndex: 50,
            overflowY: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
              height: 45,
              backgroundColor: "rgb(196, 196, 196)",
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: 45,
              left: 0,
              height: "calc(100% - 45px)",
              backgroundColor: "rgb(226, 226, 226)",
              overflowY: "auto",
            }}
          >
            <GameList onGameChanged={this.props.onGameChanged} />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: this.state.opened ? "5%" : 6,
            top: 6,
            width: this.state.opened ? "88%" : 100,
            height: 30,
            backgroundColor: "rgb(226, 226, 226)",
            border: "2px solid rgb(113, 113, 113)",
            borderRadius: 15,
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none", // for unselectable text
            transition: "all 0.5s ease 0s",
            fontWeight: "bold",
            color: "rgb(56, 56, 56)",
          }}
          onClick={() => {
            this.onMenuClick();
          }}
        >
          {this.state.opened ? "HIDE GAMES" : "GAMES"}
        </div>
      </div>
    );
  }
}
