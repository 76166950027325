import React, { Component } from "react";

export default class InfoField extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          outline: "1px solid rgb(124, 124, 124)",
          backgroundColor: "rgb(231, 231, 231)",
          boxShadow: "inset 0px 0px 2px rgb(108, 108, 108)",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            paddingRight: 100,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: "100%",
              overflow: "hidden",
              paddingLeft: 8,
              paddingTop: 4,
              color: "rgb(57, 57, 57)",
            }}
          >
            {this.props.text}
          </div>
        </div>
      </div>
    );
  }
}
