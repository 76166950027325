import React, { Component } from "react";
import Styles from "./Styles";

export default class ModelInfo extends Component {
  // props has graph
  state = {
    modelName: "",
  };

  render() {
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          backgroundColor: "rgb(240,240,240)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -1,
            height: 30,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(245, 245, 245)",
            backgroundColor: "rgb(125, 125, 125)",
            fontWeight: "bold",
            userSelect: "none", // for unselectable text
          }}
        >
          MODEL INFO
        </div>
        <div
          style={{
            position: "absolute",
            top: 45,
            left: 15,
            height: "70%",
            width: "92%",
            overflow: "auto",
          }}
        >
          <div style={{ paddingBottom: 3 }}>
            {"Model name: "}
            <b>{this.props.modelName}</b>
          </div>
          {this.props.locked ? (
            <div />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.modelName == "") return;
                this.props.onModelNameUpdated(this.state.modelName);
              }}
              style={{ paddingBottom: 7 }}
            >
              <input
                type="text"
                name="name"
                placeholder="New Model Name"
                value={this.state.modelName}
                onChange={(event) => {
                  this.setState({ modelName: event.target.value });
                }}
                style={{ width: 110, marginRight: 5 }}
              />
              <button>SET</button>
            </form>
          )}
          <div>
            {"Model is: " +
              (this.props.graph && this.props.graph.valid
                ? "valid."
                : "invalid.")}
          </div>
          <div>
            {"Model height: " +
              (this.props.graph ? this.props.graph.height : 0) +
              "."}
          </div>
          <div>
            {"Model contains: " +
              (this.props.graph ? this.props.graph.totalNodes : 0) +
              " nodes."}
          </div>
          <div>
            {"Total weights: " +
              (this.props.graph ? this.props.graph.totalW : 0) +
              "."}
          </div>
          <div>
            {"Total computations: " +
              (this.props.graph ? this.props.graph.totalComp : 0) +
              "."}
          </div>
          <div>
            {"Game input dims: " +
              (this.props.gameInputDims
                ? JSON.stringify(this.props.gameInputDims)
                : "[]") +
              "."}
          </div>
        </div>
      </div>
    );
  }
}
