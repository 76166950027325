import Rules from "./Rules";

function GetEmptyBoard() {
  let board = [];
  for (let j = 0; j < 6; j++) {
    let row = [];
    for (let k = 0; k < 7; k++) {
      row.push(0);
    }
    board.push(row);
  }
  return board;
}

export default class GameEngine {
  constructor() {
    this.reset();
  }

  reset() {
    this.state = {
      board: Rules.initBoard(),
      player: 0, // 0,1, represents whose turn it is
      moveId: 0, // beginning of the game
      playerScores: [0, 0], // this is aa must have
      score: 0,
      over: false,
      winner: 0,
      lastMove: undefined,
    };
  }

  clone() {
    let copy = new GameEngine();
    for (let key in this.state) {
      // FIXME: This need to be game independent!!!
      if (key === "board") {
        copy.state[key] = this.state[key].map((row) => {
          return [...row];
        });
      } else if (key === "playerScores") {
        copy.state[key] = [...this.state[key]];
      } else {
        copy.state[key] = this.state[key];
      }
    }
    return copy;
  }

  nMoves(player) {
    // NOTE: for both players we have 7 available moves
    return 7;
  }

  nPlayers() {
    return 2;
  }

  getAvailableMoves() {
    return Rules.getAvailableMoves(this.state.board);
  }

  // FIXME: should this be outside of the game?
  inputAdapter() {
    // NOTE: board format in trained model is upside down.
    // NOTE: board model in our game is on a side (row by row)
    // turn is 0 or 1
    // NOTE: Model accepts 2 boards: 1 is for player whose turn (0,1 if there is coin or not)
    // 1 is for other player (0,1 if there is coin or not)

    let board = this.state.board;
    let turn = this.state.player;
    let input1 = GetEmptyBoard();
    let input2 = GetEmptyBoard();
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        if ((turn == 0 && board[i][j] == 0) || (turn == 1 && board[i][j] == 1))
          input1[6 - j - 1][i] = 1;
        if ((turn == 1 && board[i][j] == 0) || (turn == 0 && board[i][j] == 1))
          input2[6 - j - 1][i] = 1;
      }
    }
    // NOTE: no batch dimention here
    return {
      dims: [2, 6, 7],
      input: [input1, input2],
    };
  }

  checkGameOver() {
    if (this.state.lastMove == undefined) return;
    let res = Rules.checkGameOver(this.state.board, this.state.lastMove);
    this.state.over = res.over;
    if (this.state.over) {
      //console.log("game is over");
      this.state.winner =
        res.winner == "first" ? 0 : res.winner == "second" ? 1 : -1;
      if (this.state.winner == -1) {
        this.state.playerScores[0] = 0;
        this.state.playerScores[1] = 0;
      } else {
        // FIXME: looks like having large numbers is crucial for the algorihtm
        // not losing should be treated as the highest priority
        this.state.playerScores[this.state.winner] = 1;
        this.state.playerScores[1 - this.state.winner] = -1;
      }
    }
  }

  doMove(move) {
    let ret = Rules.executeMove(this.state.board, move, this.state.player);
    if (ret) {
      this.state.player = 1 - this.state.player;
      this.state.lastMove = move;
      this.state.moveId++;
    }
    this.checkGameOver();
    return ret;
  }
}
