import React, { Component } from "react";
import Consts from "./Consts";
import Styles from "./Styles";

class Circle extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    const canvas = this.ref.current;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle =
      this.props.player == 0 ? "rgb(244, 204, 152)" : "rgb(115, 108, 233)";
    ctx.beginPath();
    ctx.arc(
      Consts.cellDim.w / 2,
      Consts.cellDim.h / 2,
      Consts.cellDim.h / 2.1,
      0,
      2 * Math.PI
    );
    ctx.fill();
  }

  render() {
    return <canvas ref={this.ref} />;
  }
}

export default class Coin extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: Consts.cellDim.w,
          height: Consts.cellDim.h,
          left: this.props.pos.x * Consts.cellDim.w,
          bottom: this.props.pos.y * Consts.cellDim.h,
          opacity: this.props.opacity,
        }}
      >
        <Circle player={this.props.player} />
      </div>
    );
  }
}
