import React, { Component } from "react";

export default class ModelList extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {!this.props.modelListProps.models ||
        this.props.modelListProps.models.length == 0 ? (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 12,
              pointerEvens: "none",
            }}
          >
            {"NO MODELS"}
          </div>
        ) : (
          this.props.modelListProps.playerTypes.map((type, id) => {
            return (
              <div
                key={id}
                style={{
                  position: "relative",
                  width: "100%",
                  height: 20,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: 20,
                  }}
                >
                  {"" + id + ": "}
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: 100,
                    height: 20,
                  }}
                >
                  <select
                    onChange={(e) => {
                      this.props.modelListProps.setSelected(id, e.target.value);
                    }}
                    value={this.props.modelListProps.selected[id] || ""}
                  >
                    {this.props.modelListProps.models.map((option) => {
                      return <option key={"option_" + option}>{option}</option>;
                    })}
                  </select>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }
}
