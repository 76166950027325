import React, { Component } from "react";
import { Rnd } from "react-rnd";
import HighDiv from "./HighDiv";
import Consts from "./Consts";
import Styles from "./Styles";

function SanitizePx(str) {
  if (typeof str != "string") return str;
  return parseInt(str.replace("px", ""));
}

export default class RndWrapper extends Component {
  render() {
    return (
      <Rnd
        ref={this.props.state.ref}
        size={this.props.state}
        position={this.props.state}
        bounds="parent"
        minWidth={Consts.cellSize}
        minHeight={Consts.cellSize}
        onDragStop={(e, d) => {
          //let b1 = { ...state };
          //b1.x = d.x;
          //b1.y = d.y;
          //console.log(state, b1, d);
          // NOTE: there is a bug here: the element is moving away from the grid a little.
          this.props.setState({ dragged: false });
          //updateXarrow();
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          let b1 = { ...this.props.state };
          b1.width = SanitizePx(ref.style.width);
          b1.height = SanitizePx(ref.style.height);
          b1.x = SanitizePx(position.x);
          b1.y = SanitizePx(position.y);
          this.props.setState(b1);
        }}
        onDrag={(e, d) => {
          let b1 = { ...this.props.state };
          b1.x = SanitizePx(d.x);
          b1.y = SanitizePx(d.y);
          b1.dragged = true;
          //console.log("drag", JSON.stringify(b1));
          this.props.setState(b1);
        }}
        onResize={(e, direction, ref, delta, position) => {
          let b1 = { ...this.props.state };
          b1.width = SanitizePx(ref.style.width);
          b1.height = SanitizePx(ref.style.height);
          b1.x = SanitizePx(position.x);
          b1.y = SanitizePx(position.y);
          //console.log("resize", JSON.stringify(b1));
          this.props.setState(b1);
        }}
        dragGrid={[Consts.cellSize, Consts.cellSize]}
        resizeGrid={[Consts.cellSize, Consts.cellSize]}
        disableDragging={!this.props.state.dragging || this.props.locked}
        enableResizing={this.props.state.resizing && !this.props.locked}
      >
        <div
          style={{
            ...Styles.dummyStyle,
            opacity: this.props.state.temp ? 0.5 : 1,
          }}
        >
          <HighDiv
            id={this.props.id}
            name={this.props.id}
            isSelected={this.props.isSelected}
            onClick={this.props.onClick}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            noChange={this.props.state.temp}
            text={
              "w: " +
              (this.props.info && this.props.info.w ? this.props.info.w : 0)
            }
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              left: 0,
              top: 0,
              backgroundColor: this.props.color,
              border: "1px black solid",
              //borderRadius: 4,
            }}
          />
          <HighDiv
            id={this.props.id + "_pin_left"}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClick={this.props.onClick}
            noChange={this.props.state.temp}
            text="~" //"A"
            style={{
              position: "absolute",
              height: (2 * Consts.cellSize) / 2,
              width: (2 * Consts.cellSize) / 2,
              left: 0,
              bottom: 0,
              backgroundColor: "rgb(240, 240, 240)",
              border: "1px black solid",
              cursor: "grab",
            }}
          />
        </div>
      </Rnd>
    );
  }
}
