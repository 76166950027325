function IsPinId(id) {
  return (
    id.endsWith("_pin_top") ||
    id.endsWith("_pin_bottom") ||
    id.endsWith("_pin_left") ||
    id.endsWith("_pin_right")
  );
}

function GetStartEndFromEdgeHash(hash) {
  return hash.split("$");
}

function GetEdgeHash(start, end) {
  return start + "$" + end;
}

function StripPinSuffix(id) {
  let reg = /_pin_top|_pin_bottom|_pin_left|_pin_right/;
  let newid = id.replace(reg, "");
  //console.log(id, newid);
  return newid;
}

const Utils = {
  isPinId: IsPinId,
  getStartEndFromEdgeHash: GetStartEndFromEdgeHash,
  getEdgeHash: GetEdgeHash,
  stripPinSuffix: StripPinSuffix,
};

export default Utils;
