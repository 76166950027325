import Consts from "./Consts";

const Styles = {
  dummyStyle: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
  },
  boardStyle: {
    position: "absolute",
    left: Consts.cellDim.w,
    top: 0,
    width: Consts.boardDim.w * Consts.cellDim.w,
    height: (Consts.boardDim.h + 1) * Consts.cellDim.h,
    //outline: "solid 2px rgb(116, 116, 116)",
  },
};

export default Styles;
