const Consts = {
  cellSize: 100,
  space: 6,
  cellColors: {
    0: "rgb(215, 215, 215)",
    2: "rgb(252, 246, 240)",
    4: "rgb(254, 236, 200)",
    8: "rgb(252, 204, 121)",
    16: "rgb(250, 152, 81)",
    32: "rgb(250, 110, 79)",
    64: "rgb(252, 71, 66)",
    128: "rgb(255, 247, 135)",
    256: "rgb(255, 247, 135)",
    512: "rgb(249, 239, 111)",
    1024: "rgb(249, 237, 91)",
    2048: "rgb(244, 218, 49)",
    4096: "rgb(52, 52, 52)",
    8192: "rgb(34, 34, 34)",
    16384: "rgb(33, 33, 33)",
    32768: "rgb(15, 15, 15)",
    64556: "rgb(20, 20, 20)",
    129012: "rgb(0, 0, 0)",
  },
};

export default Consts;
