import Utils from "./Utils";
import { palette } from "./Palette";

export default class Graph {
  constructor(props) {
    this.props = props;
    this.reset();
  }

  reset() {
    // info: {type,w,inShape,outShape,c, level},
    // w is number of weights
    // c is number of computations
    // level is longest distance from given node to the output
    this.nodes = {}; // mapping from node to edges {in: {} out: {}, info: {}, params: {}}
    this.edges = {};
    this.inputs = {};
    this.outputs = {};
    this.recompute();
    this.props.onUpdated();
  }

  // NOTE: Also computes the graph height, and sets level attributes
  // more precisely sets the "level" to the longest distance from any output.
  cyclic() {
    // DFS from outputs to find a cycle
    for (let out in this.outputs) {
      let stack = [[out, -1]];
      let stackSet = {};
      stackSet[out] = true;

      while (stack.length > 0) {
        let cur = stack[stack.length - 1][0];
        let nextChildId = stack[stack.length - 1][1] + 1;

        if (nextChildId >= Object.keys(this.nodes[cur].in).length) {
          // backtrack
          stack.pop();
          delete stackSet[cur];
          continue;
        }
        let nextChildEdge = Object.keys(this.nodes[cur].in)[nextChildId];
        let nextChild = Utils.getStartEndFromEdgeHash(nextChildEdge)[0];
        if (stackSet[nextChild]) {
          console.log("found a cycle. node:", nextChild, "trace:", stackSet);
          return true; // found a cycle
        }
        this.nodes[nextChild].level = Math.max(
          this.nodes[nextChild].level,
          stack.length
        );
        console.log(cur, this.nodes[cur].info.level);
        stack[stack.length - 1][1] += 1;
        stack.push([nextChild, -1]);
        stackSet[nextChild] = true;
      }
    }
    return false;
  }

  onNodeUpdate(id, update) {
    console.log("update received:", id, update);
    // NOTE: only params are getting updated!!!
    // FIXME: for "inShape" and "outShape" we need to update the "info" too
    for (let key in update) {
      this.nodes[id].params[key] = update[key];
    }
    this.props.onUpdated();
  }

  getStatsRec(id) {
    let stats = {
      w: 0,
      c: 0,
      inShape: [0, 0],
      outShape: [0, 0],
    };
    //console.log(id);
    // FIXME: THIS ARE JSUT DUMMY VALUES!!!!!
    // NEED TO PROVIDE ACTUAL LOGIC HERE!!!!
    if (this.nodes[id].type == "in") {
      stats.inShape = [10, 10];
      stats.outShape = [10, 10];
      this.nodes[id].info = stats;
      this.nodes[id].computed = true;
      return;
    } else if (Object.keys(this.nodes[id].in).length == 0) {
      // node is not an input but it has no ancestors
      this.nodes[id].info = stats;
      console.log("no input found for node", id);
      return;
    }

    for (let edge in this.nodes[id].in) {
      let parent = Utils.getStartEndFromEdgeHash(edge)[0];
      if (!this.nodes[parent].computed) this.getStatsRec(parent);
      let parentInfo = this.nodes[parent].info;
      // parent is bad, hence the whole network is declared bad.
      if (parentInfo.inShape.length == 0 || parentInfo.inShape[0] == 0) {
        this.nodes[id].info = stats;
        return;
      }
      stats.inShape[0] += parentInfo.outShape[0];
      // FIXME: all the stats could be shared, so need to change this logic....
    }
    stats.outShape[0] = stats.inShape[0];

    if (this.nodes[id].type != "ou") {
      stats.w += stats.inShape[0];
      stats.c += stats.inShape[0];
    }
    this.nodes[id].info = stats;
    this.nodes[id].computed = true;
  }

  // utility for graph traversal with a callback
  // cb(id, node), NOTE: node is read only!
  // guarantees that node will be returned after all its parents
  traverseFromInputs(cb) {
    // sort nodes by level and return one by one (decending)
    let ids = Object.keys(this.nodes);
    ids.sort((a, b) => {
      return this.nodes[a].level > this.nodes[b].level
        ? -1
        : this.nodes[a].level < this.nodes[b].level
        ? 1
        : 0;
    });
    for (let id of ids) {
      //console.log(id, this.nodes[id].level);
      cb(id, this.nodes[id]);
    }
  }

  // if valid will build the graph nodes info
  // traverse from outputs and recursively compute info for parents
  recompute() {
    this.totalW = 0;
    this.totalNodes = 0;
    this.totalComp = 0;
    this.height = 0;
    this.valid = false;

    // reset all the ndoes computed atttributes
    for (let key in this.nodes) {
      this.nodes[key].computed = false;
      this.nodes[key].level = 0;
    }

    // NOTE: cyclic also fills in the node levels
    if (this.cyclic()) return;

    for (let out in this.outputs) {
      // FIXME: need to account for outputs that are inputs to other outputs!!!
      // NOTE: if output has loose ends (i.e., not connected to inputs,
      // we will declare network invalid
      // NOTE: stats also contains accumulated info from the recursive calls
      this.getStatsRec(out);
      // inShape empty array will be returned if there is no conenction to outputs
      if (
        this.nodes[out].info.inShape.length == 0 ||
        this.nodes[out].info.inShape[0] == 0
      )
        return;
    }

    let computedNodes = 0;
    for (let key in this.nodes) {
      if (!this.nodes[key].computed) continue;
      computedNodes += 1;
      this.totalW += this.nodes[key].info.w;
      this.totalNodes += 1;
      this.totalComp += this.nodes[key].info.c;
      this.height = Math.max(this.height, this.nodes[key].level + 1);
    }

    // at last looks like model is valid
    this.valid = computedNodes > 0;
  }

  maybeRemoveNode(id, noUpdate) {
    if (!id) return false;
    if (!this.nodes[id]) return false;
    // when deleting the ndoe we also delete all connecting edges to it.
    for (let key in this.nodes[id].out) {
      let startEnd = Utils.getStartEndFromEdgeHash(key);
      this.maybeRemoveEdge(key, startEnd[0], startEnd[1], true);
    }
    for (let key in this.nodes[id].in) {
      let startEnd = Utils.getStartEndFromEdgeHash(key);
      this.maybeRemoveEdge(key, startEnd[0], startEnd[1], true);
    }

    // NOTE: this does not remove edges, as UI will call removeEdges on its own
    delete this.nodes[id];
    if (this.inputs[id]) {
      delete this.inputs[id];
    } else if (this.outputs[id]) {
      delete this.outputs[id];
    }
    if (!noUpdate) {
      this.recompute();
      this.props.onUpdated();
    }
    return true; // NOTE: we allow any changes instead of returning this.valid;
  }

  maybeRemoveEdge(id, noUpdate) {
    if (!id) return false;
    let startEnd = Utils.getStartEndFromEdgeHash(id);
    delete this.nodes[startEnd[0]].out[id];
    delete this.nodes[startEnd[1]].in[id];
    delete this.edges[id];
    if (!noUpdate) {
      this.recompute();
      this.props.onUpdated();
    }
    return true; // NOTE: we allow any changes instead of returning this.valid;
  }

  maybeAddNode(id, type, params, noUpdate) {
    if (!id || !type) return false;
    // NODE contains stats?
    if (this.nodes[id] != undefined) return false;
    console.log("adding node", id);
    this.nodes[id] = {
      in: {},
      out: {},
      type,
      info: {},
      level: 0,
      params: { ...palette[type].params, ...(params ? params : {}) },
    };
    //console.log(this.nodes[id], params);
    if (type == "in") {
      this.inputs[id] = true;
    } else if (type == "ou") {
      this.outputs[id] = true;
    }
    if (!noUpdate) {
      this.recompute();
      this.props.onUpdated();
    }
    return true; // NOTE: we allow any changes instead of returning this.valid;
  }

  maybeAddEdge(start, end, noUpdate) {
    let id = Utils.getEdgeHash(start, end);
    if (this.edges[id] != undefined) return false;
    if (start == end) return false;

    this.edges[id] = { start, end };
    this.nodes[start].out[id] = true;
    this.nodes[end].in[id] = true;

    if (!noUpdate) {
      this.recompute();
      this.props.onUpdated();
    }
    return true; // NOTE: we allow any changes instead of returning this.valid;
  }
}
