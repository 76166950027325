import React, { Component } from "react";

function TitleCase(str) {
  return str
    .split(/\s+/)
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");
}

export default class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          height: 26,
          width: this.props.values.length * 45,
        }}
      >
        <div
          style={{
            fontFamily: "Lucida Grande, Tahoma, Verdana, sans-serif",
            position: "absolute",
            left: 0,
            top: 0,
            height: 26,
            width: this.props.values.length * 45,
            backgroundColor: "#e4e4e4",
            borderRadius: 3,
            boxShadow:
              "inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1)",
          }}
        >
          {this.props.values.map((val) => {
            return (
              <span key={val}>
                <input
                  style={{ position: "relative", zIndex: 1, display: "none" }}
                  type="radio"
                  name="switch"
                  checked={this.props.selected == val}
                  onChange={() => {}}
                />
                <div
                  onClick={() => this.props.onSwitchValue(val)}
                  style={{
                    zIndex: 2,
                    position: "absolute",
                    top: 0,
                    left: 45 * this.props.values.indexOf(val),
                    width: 45,
                    height: "100%",
                    fontSize: 11,
                    color:
                      this.props.selected == val
                        ? "rgba(255, 255, 255,1)"
                        : "rgba(0, 0, 0, 0.6)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {TitleCase(val)}
                </div>
              </span>
            );
          })}
          <span
            style={{
              display: "block",
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              width: 45,
              height: 26,
              background: "#216ba5",
              borderRadius: 3,
              transition: "left 0.25s ease-out",
              left: `${
                (this.props.values.indexOf(this.props.selected) /
                  this.props.values.length) *
                100
              }%`,
            }}
          />
        </div>
      </div>
    );
  }
}
