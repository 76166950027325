import React, { Component } from "react";

function multiplyRgbColor(color) {
  color = color
    .substring(4, color.length - 1)
    .replace(/ /g, "")
    .split(",");
  let ret = [];
  color.map((r) => ret.push(Math.min(255, Math.floor(r * 1.1))));
  return "rgb(" + ret[0] + "," + ret[1] + "," + ret[2] + ")";
}

export default class HighDiv extends Component {
  componentDidMount() {
    //console.log(this.props);
    this.highColor = multiplyRgbColor(this.props.style.backgroundColor); //"yellow"; //this.props.style.backgroundColor;
    this.selColor = multiplyRgbColor(this.props.style.backgroundColor); //"yellow"; //this.props.style.backgroundColor;
    this.highBorder = "2px solid rgb(90, 90, 90)"; //this.props.style.border;
    this.selBorder = "3px solid rgb(235, 77, 77)"; //this.props.style.border;
  }

  state = {
    clicked: false,
    high: false,
  };

  render() {
    //console.log("render from highdiv", this.props.id);
    let color = this.props.isSelected
      ? this.selColor
      : this.state.clicked
      ? this.props.style.backgroundColor
      : this.state.high
      ? this.highColor
      : this.props.style.backgroundColor;
    let border = this.props.isSelected
      ? this.selBorder
      : this.state.high
      ? this.highBorder
      : this.props.style.border;
    return (
      <div
        id={this.props.id}
        onClick={() => {
          if (this.props.noChange) return;
          // temporarily change style
          if (this.props.clickable) {
            this.setState({ clicked: true });
            setTimeout(() => {
              this.setState({ clicked: false });
            }, 100);
          }
          if (this.props.onClick) this.props.onClick(this.props.id);
        }}
        onMouseEnter={() => {
          if (this.props.noChange) return;
          this.setState({ high: true });
          if (this.props.onMouseEnter) this.props.onMouseEnter(this.props.id);
        }}
        onMouseLeave={() => {
          if (this.props.noChange) return;
          this.setState({ high: false });
          if (this.props.onMouseLeave) this.props.onMouseLeave(this.props.id);
        }}
        style={{
          ...this.props.style,
          backgroundColor: color,
          outline: border,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          userSelect: "none", // for unselectable text
        }}
      >
        {this.props.text == undefined ? "" : this.props.text}
      </div>
    );
  }
}
