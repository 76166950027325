import React, { Component } from "react";
import { Rnd } from "react-rnd";
import Consts from "./Consts";
import Styles from "./Styles";
import HighDiv from "./HighDiv";

// FIXME: RIGHT NOW EACH ELEMENT IN PALETTE SHOULD HAVE 2 digit key
// since we are adding increments
// NOTE: params should follow the names in tfjs
const palette = {
  co: {
    id: "co", // id is important, it is also being used to infer id of the node
    params: {
      // NOTE: it looks like params are the same for conv1d,conv2d and so on
      filters: 2,
      kernelSize: 2,
      padding: "same",
      strides: 1,
      dataFormat: "channelsFirst",
      activation: "relu",
      kernelInitializer: "randomUniform",
      useBias: true,
      biasInitializer: "randomUniform",
    },
    ui: {
      width: 6 * Consts.cellSize,
      height: 2 * Consts.cellSize,
      color: "rgb(161, 242, 159)",
      dragging: true,
      resizing: true,
      text: "CONV",
    },
  },
  de: {
    id: "de",
    params: {
      units: 10,
      activation: "relu",
      kernelInitializer: "randomUniform",
      useBias: true,
      biasInitializer: "randomUniform",
    }, // dense layer is special since its params is effectively the output shape, which hopefully can be determined automatically
    ui: {
      width: 6 * Consts.cellSize,
      height: 2 * Consts.cellSize,
      color: "rgb(246, 181, 138)",
      dragging: true,
      resizing: true,
      text: "DENSE",
    },
  },
  cb: {
    id: "cb", // convolutional block
    params: {
      // NOTE: all params are the same, but an extra param layers
      layers: 2,
      filters: 2,
      kernelSize: 2,
      padding: "same",
      strides: 1,
      dataFormat: "channelsFirst",
      activation: "relu",
      kernelInitializer: "randomUniform",
      useBias: true,
      biasInitializer: "randomUniform",
    },
    ui: {
      width: 6 * Consts.cellSize,
      height: 4 * Consts.cellSize,
      color: "rgb(103, 185, 101)",
      dragging: true,
      resizing: true,
      text: "CBLOCK",
    },
  },
  db: {
    id: "db", // dense block
    params: {
      layers: 2,
      units: 10,
      activation: "relu",
      kernelInitializer: "randomUniform",
      useBias: true,
      biasInitializer: "randomUniform",
    },
    ui: {
      width: 6 * Consts.cellSize,
      height: 4 * Consts.cellSize,
      color: "rgb(228, 161, 81)",
      dragging: true,
      resizing: true,
      text: "DBLOCK",
    },
  },
  rb: {
    id: "rb", // res block
    params: {
      layers: 2,
      filters: 2,
      kernelSize: 2,
      padding: "same",
      strides: 1,
      dataFormat: "channelsFirst",
      activation: "relu",
      kernelInitializer: "randomUniform",
      useBias: false,
      biasInitializer: "randomUniform",
    },
    ui: {
      width: 6 * Consts.cellSize,
      height: 4 * Consts.cellSize,
      color: "rgb(246, 123, 171)",
      dragging: true,
      resizing: true,
      text: "RBLOCK",
    },
  },
  in: {
    id: "in",
    params: { shape: [10] }, // param for the input layer is input shape
    ui: {
      width: 6 * Consts.cellSize,
      height: 2 * Consts.cellSize,
      color: "rgb(163, 224, 240)",
      dragging: true,
      resizing: true,
      text: "INPUT",
    },
  },
  ou: {
    id: "ou",
    params: { units: 1 }, // param for output is outshape; NOTE: it is 1dim!!!
    ui: {
      width: 6 * Consts.cellSize,
      height: 2 * Consts.cellSize,
      color: "rgb(198, 138, 246)",
      dragging: true,
      resizing: true,
      text: "OUTPUT",
    },
  },
};

class PaletteSubEl extends Component {
  componentDidMount() {
    this.reset();
  }
  state = {
    position: { x: 0, y: 0 },
  };
  reset() {
    // NOTE: timeout is so that dragging will prevent clicking
    setTimeout(() => {
      this.isBeingDragged = false;
    }, 40);
    this.setState({ position: this.props.position });
  }
  render() {
    return (
      <Rnd
        size={this.props.size}
        position={this.state.position}
        enableResizing={false}
        minWidth={1}
        minHeight={1}
        disableDragging={!this.props.dragging}
        onDragStop={(e, d) => {
          this.props.onDropped();
          this.reset();
          //let position = { ...this.state.position };
          //position.x = d.x;
          //position.y = d.y;
          //this.setState({ position });
        }}
        onDrag={(e, d) => {
          this.props.onDragged();
          let position = { ...this.state.position };
          position.x = d.x;
          position.y = d.y;
          this.isBeingDragged = true;
          this.setState({ position });
        }}
      >
        <HighDiv
          id={this.props.id}
          text={this.props.text}
          onClick={() => {
            if (!this.isBeingDragged) this.props.onClick(this.props.id);
          }}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            backgroundColor: this.props.color,
            border: "1px black solid",
            //borderRadius: 4,
          }}
        />
      </Rnd>
    );
  }
}

class PaletteEl extends Component {
  render() {
    return (
      <React.Fragment>
        <PaletteSubEl
          id={"nodragging" + this.props.id}
          position={this.props.position}
          color={this.props.color}
          size={this.props.size}
          dragging={false}
          text={this.props.text}
        />
        <PaletteSubEl
          id={"dragging" + this.props.id}
          text={this.props.text}
          position={this.props.position}
          color={this.props.color}
          size={this.props.size}
          dragging={true}
          onDragged={this.props.onDragged}
          onDropped={this.props.onDropped}
          onClick={this.props.onClick}
        />
      </React.Fragment>
    );
  }
}

export default class Palette extends Component {
  render() {
    let heights = [(2 * Consts.cellSize) / 2];
    Object.keys(palette).map((key) => {
      heights.push(
        heights[heights.length - 1] +
          palette[key].ui.height +
          (2 * Consts.cellSize) / 2
      );
    });
    return (
      <div
        style={{
          ...Styles.dummyStyle,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -1,
            height: 30,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(245, 245, 245)",
            backgroundColor: "rgb(125, 125, 125)",
            fontWeight: "bold",
            userSelect: "none", // for unselectable text
          }}
        >
          PALETTE
        </div>
        <div
          style={{
            ...Styles.dummyStyle,
            top: 30,
            backgroundColor: "rgb(240,240,240)",
            overflowY: "scroll",
            paddingRight: 20,
          }}
        >
          <div
            style={{
              ...Styles.dummyStyle,
              height: heights[heights.length - 1] + 2 * Consts.cellSize,
            }}
          >
            {Object.keys(palette).map((key, id) => {
              let obj = palette[key];
              return (
                <PaletteEl
                  id={key}
                  key={key}
                  text={obj.ui.text}
                  size={{ width: obj.ui.width, height: obj.ui.height }}
                  position={{
                    x: (8 * Consts.cellSize - obj.ui.width) / 2,
                    y: heights[id],
                  }}
                  color={obj.ui.color}
                  onDragged={() => this.props.onDragged(key)}
                  onDropped={() => this.props.onDropped(key)}
                  onClick={() => this.props.onPaletteClick(key)}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export { palette };
