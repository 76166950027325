// FIXME: these constants should be adjustable from the UI
const Consts = {
  cpuct: 1,
};

// FIXME: THIS SHOULD BE PER GAME
function OutputAdapter(pred) {
  // convert output back into value/policy
  return {
    value: pred[0], //.arraySync(), // NOTE: dataSync() will do data convertion and is probably slower
    policy: pred[1], //.arraySync(), // NOTE: lots of time is spent here when data is ported from GPU to CPU
  };
}

function GetTime() {
  return new Date().getTime();
}

function Softmax(arr) {
  return arr.map(function (value, index) {
    return (
      Math.exp(value) /
      arr
        .map(function (y /*value*/) {
          return Math.exp(y);
        })
        .reduce(function (a, b) {
          return a + b;
        })
    );
  });
}

function Normalize(arr) {
  let minn = Math.min(...arr);
  let summ = arr.reduce((a, v) => a + v);

  // taking min value and
  return arr.map((el) => {
    return (el - minn) / summ;
  });
}

function Node() {
  return {
    id: "", // hash of the state? or UID?
    engine: null, // gameEngine object at the node
    //move: null, // NOTE: not required as gameEngine.state contains the lastMove
    n: 0,
    ws: [0, 0], // same as w but per player
    // values are values associated with the incoming edge, hence it also contains the move score itself
    values: [0, 0], // lastMove score + value returned by the NN (for the active player and 0 for the other)
    policy: [], // policy returned by the NN
    qs: [0, 0], // w/n same as q but per player
    isLeaf: true,
    children: {}, //{childId}
  };
}

// NOTE: MCTS class is memoryless, i.e., it should be reconstructed everytime we
// make a (real) move
// FIXME: implement memory, and move alongside the tree ? (tried some time ago didn't give much advantage)

export default class MCTSAsync {
  constructor(ml) {
    this.ml = ml;
    this.logging = false;
    this.nodes = {};
    this.nextId = 0;
  }

  LOG(...messages) {
    if (this.logging) {
      console.log(...messages);
    }
  }

  createNode(engine) {
    let node = Node();
    // NOTE: clonning engine here to avoid messing up the state
    // PROFILE: 2s/60s
    node.engine = engine.clone();
    node.id = this.nextId + 1;
    // FIXME: more than 2 players
    node.values = [0, 0];
    node.n = 0;
    node.policy = new Array(node.engine.nMoves(node.engine.state.player));
    node.policy.fill(1 / node.engine.nMoves(node.engine.state.player));
    this.nextId++;
    this.nodes[node.id] = node;
    return node;
  }

  initRootAsync(engine, onDone) {
    let node = this.createNode(engine);
    this.root = node.id;

    this.ml[engine.state.player].getModelScoreBatchAsync(
      [{ id: 0, input: engine.inputAdapter() }],
      (res) => this.finishInitRoot(res, onDone)
    );
  }

  finishInitRoot(res, onDone) {
    let node = this.nodes[this.root];
    let { value, policy } = OutputAdapter(res[0].output);
    if (policy.length != node.engine.nMoves(node.engine.state.player)) {
      this.LOG(
        "ERROR!!! MODEL RETURNED BAD POLICY",
        policy.length,
        node.engine.nMoves(node.engine.state.player)
      );
    } else {
      if (value.length != 1) {
        this.LOG("ERROR!!!!! value size is different from 1!!!", value);
      }
      // NOTE: we update only the value of the child player (WRONG????)
      // FIXME: for 0-sum games we add value for one player and subtract for the other
      for (let i = 0; i < node.values.length; i++) {
        if (i == node.engine.state.player) node.values[i] += value[0];
        else node.values[i] -= value[0];
      }
      node.qs = [...node.values];
      node.ws = [...node.values];
      node.policy = policy;
      //console.log("init values", JSON.stringify(node.values));
    }
    onDone();
  }

  // NOTE: rnd simulations go first
  // NOTE: if maxTime is reached algo will immediately return
  simulateAsync(props, onDone) {
    this.simIter = 0;
    this.gameOverHits = 0;
    this.startSimulationTime = GetTime();
    // TODO: check if we need to pass some pause signal in runtime.
    this.simProps = { ...props };
    this.simProps.extraWinningScore = props.extraWinningScore || 0;
    this.onSimDone = onDone;
    this.simRec();
  }

  simRec() {
    // TODO: check for pause signal?
    // FIXME: need to update the time counting if pause is introduced
    if (
      (this.simProps.nSim && this.simIter >= this.simProps.nSim) ||
      (this.simProps.maxTime &&
        this.simProps.maxTime != 0 &&
        GetTime() - this.startSimulationTime > this.simProps.maxTime)
    ) {
      this.LOG(
        "number of sims performed:",
        this.simIter + 1,
        "gameover hits:",
        this.gameOverHits
      );
      this.onSimDone();
      return;
    }
    this.simIter++;
    //if (this.simIter % Math.floor(this.simProps.nSim / 3) == 0) this.LOG("simulation:", this.simIter);
    //console.log(JSON.stringify(this.simProps));
    let leaf = this.goToLeaf(
      this.simProps.nSim && this.simProps.randFrac
        ? this.simIter < this.simProps.nSim * this.simProps.randFrac
        : false /*random*/
    );
    // NOTE: expansion is async since it needs to call ML block
    this.expandAsync(leaf, () => {
      this.backfill();
      // NOTE: Async call here to relieve UI
      setTimeout(() => this.simRec(), 0);
    });
  }

  getBestMoveAsync(engine, extraProps, cb) {
    this.initRootAsync(engine, () => {
      this.simulateAsync(extraProps, () => this.finishGetBestMove(cb));
    });
  }

  finishGetBestMove(cb) {
    //this.LOG("getbestmove called");
    if (true) {
      let toPrint = new Array(this.nodes[this.root].engine.nMoves());
      toPrint.fill(0);
      for (let key in this.nodes[this.root].children) {
        toPrint[this.nodes[key].engine.state.lastMove] = {
          n: this.nodes[key].n,
          qs: this.nodes[key].qs,
        };
      }
      this.LOG(
        JSON.stringify(this.nodes[this.root].engine.state),
        JSON.stringify(toPrint)
      );
    }
    let ret = this.getBestChild(this.nodes[this.root]).engine.state.lastMove;
    //console.log("best move:", ret);
    // accumulating sim data for training (if needed)
    // maps from last move to values
    let simData = {
      qs: [...this.nodes[this.root].qs],
      childrenQs: {},
      childrenN: {},
    };
    for (let key in this.nodes[this.root].children) {
      let child = this.nodes[key];
      simData.childrenQs[child.engine.state.lastMove] = [...child.qs];
      simData.childrenN[child.engine.state.lastMove] = child.n;
    }
    cb(ret, simData);
  }

  // NOTE: best child is calculated based on number of times
  // the node was visited (i.e., child.n)
  getBestChild(node) {
    if (node.engine.state.over) {
      this.LOG(node);
      this.LOG("ERROR!!! best move called on game that is already over!!");
    }
    let bestScore = -10000000;
    let bestChild = undefined;
    for (let key in node.children) {
      let child = this.nodes[key];
      if (child.n > bestScore) {
        bestScore = child.n;
        bestChild = child;
      }
    }
    //this.LOG(all);
    return bestChild;
  }

  getBestChildQU(node, probabilistic) {
    let bestQU = -10000000;
    let bestChild = undefined;
    let childrenQU = [];
    let maxQ = 0;
    for (let key in node.children) {
      let qq = Math.abs(this.nodes[key].qs[node.engine.state.player]);
      if (qq > maxQ) maxQ = qq;
    }
    let coef = Consts.cpuct * Math.sqrt(node.n);
    // FIXME: maxQ above benefits 2048, but makes Connect4 reeally bad
    maxQ = 1;

    // FIXME: policy is never negative!??

    for (let key in node.children) {
      let child = this.nodes[key];
      let childQU =
        (maxQ == 0 ? 0 : child.qs[node.engine.state.player] / maxQ) +
        (coef * node.policy[child.engine.state.lastMove]) / (1 + child.n);
      childrenQU.push(childQU);
      /*  console.log(
        key,
        child.engine.state.lastMove,
        node.engine.state.player,
        JSON.stringify(child)
      );*/
      if (childQU > bestQU) {
        bestQU = childQU;
        bestChild = child;
      }
    }

    if (!probabilistic) {
      //  this.LOG(
      //    "best:",
      //    JSON.stringify(bestChild),
      //    "others:",
      //    JSON.stringify(childrenQU)
      //  );
      //  this.LOG(node);
      return bestChild;
    }

    // FIXME: softmax will make all negative numbers about the same?

    // compute probabilities and explore move probabilistically
    // FIXME: softmax makes large numbers null
    //console.log("before", JSON.stringify(childrenQU), Math.exp(1000.0));
    childrenQU = Normalize(childrenQU);
    // FIXME: will there be an overflow for large values?
    //console.log(JSON.stringify(childrenQU));
    let r = Math.random();
    let i = 0;
    for (let key in node.children) {
      r -= childrenQU[i];
      i++;
      if (r <= 0) {
        //  this.LOG("best:", this.nodes[key]);
        //  this.LOG(node);
        return this.nodes[key];
      }
    }

    return bestChild;
  }

  goToLeaf(probabilistic) {
    this.path = [];
    //this.LOG(this.nodes);
    //this.LOG(this.root);
    let current = this.nodes[this.root];
    //this.LOG(current);
    this.path.push(current.id);
    while (current && !current.isLeaf) {
      //let prev = current;
      current = this.getBestChildQU(current, probabilistic);
      //if (!current) console.log(JSON.stringify(prev));
      this.LOG("current", JSON.stringify(current));
      this.path.push(current.id);
    }
    return current;
  }

  expandAsync(node, onDone) {
    if (!node.isLeaf) {
      this.LOG("ERROR NODE IS NOT A LEAF!!!!!");
    }
    if (node.engine.state.over) {
      //this.LOG("NODE IS AT GAME OVER!!!!!");
      this.gameOverHits++;
      onDone();
      return;
    }
    node.isLeaf = false;
    // once we get to the node we reset the qs values because
    // the temp values we assigned to make the very first move are no longer needed.
    node.qs = [0, 0];

    // NOTE: By this time the policy of the node contains probabilities for ALL moves.
    // we have to modify the policy to reflect accurately the available moves.
    // Ideally this should be done by the NN itself, but it might not, and
    // since we will get it through the softmax it may result in unreal moves
    // sucking all the probabilities.
    let availableMoves = node.engine.getAvailableMoves();
    let renormalizedPolicy = new Array(node.policy.length);
    renormalizedPolicy.fill(0);
    for (let move of availableMoves) {
      renormalizedPolicy[move] = node.policy[move];
    }
    // FIXME: do we really need it?
    // PROFILE: 5s / 70s
    //
    //node.policy = Softmax(renormalizedPolicy);
    node.policy = renormalizedPolicy;
    //  if (!node.policy[0]) this.LOG(renormalizedPolicy, node.policy);

    // Collecting leafs to eval all together in a single batch
    let toEval = [];

    let childPlayer = undefined;
    for (let move of availableMoves) {
      // NOTE: Engine is cloned inside the child node
      let child = this.createNode(node.engine);
      if (child.engine.doMove(move)) {
        if (
          childPlayer != undefined &&
          childPlayer != child.engine.state.player
        ) {
          this.LOG(
            "ERRORR CHILDREN MOVES ARE DIFFERENT!!!",
            JSON.stringify(node)
          );
        }
        childPlayer = child.engine.state.player;
        //this.LOG("childPLayer:", childPlayer);
      }

      // NOTE: We only add score associated with current move in case if its not game over.
      // Otherwise score will be added when backtracking using engine itself.
      // NOTE: The value contains the current value at a gamestate plus a future prediction
      // FIXME: this doesn't need to be the total score, it could be a difference iwth the cuurent node score
      //child.values = [...child.engine.state.playerScores];
      for (let i = 0; i < child.values.length; i++) {
        let moveValue =
          child.engine.state.playerScores[i] -
          node.engine.state.playerScores[i];
        //this.LOG(moveValue);
        child.values[i] =
          moveValue +
          (child.engine.state.over
            ? this.simProps.extraWinningScore *
              (i == child.engine.state.winner ? 1 : -1)
            : 0);
      }
      // temp values to make the very first move
      child.qs = [...child.values];

      if (!child.engine.state.over) {
        //this.LOG("child board:", JSON.stringify(child.board));
        toEval.push({ id: child.id, input: child.engine.inputAdapter() });
      }

      this.nodes[child.id] = child;
      node.children[child.id] = true;
    }

    if (toEval.length == 0) {
      // nothing to compute
      onDone();
      return;
    }

    this.ml[childPlayer].getModelScoreBatchAsync(toEval, (evalResult) =>
      this.finishExpand(childPlayer, evalResult, onDone)
    );
  }

  finishExpand(childPlayer, evalResult, onDone) {
    //this.LOG(evalResult);
    for (let res of evalResult) {
      let { value, policy } = OutputAdapter(res.output);
      if (policy.length != this.nodes[res.id].engine.nMoves(childPlayer)) {
        this.LOG(
          "ERROR!!! MODEL RETURNED BAD POLICY",
          policy.length,
          this.nodes[res.id].engine.nMoves(childPlayer)
        );
      } else {
        if (value.length != 1) {
          this.LOG("ERROR!!!!! value size is different from 1!!!", value);
        }
        // NOTE: we update only the value of the child player (WRONG????)
        // FIXME: for 0-sum games we add value for one player and subtract for the other
        for (let i = 0; i < this.nodes[res.id].values.length; i++) {
          if (i == childPlayer) this.nodes[res.id].values[i] += value[0];
          else this.nodes[res.id].values[i] -= value[0];
        }
        /*console.log(
          "update value",
          this.nodes[res.id].values[0],
          JSON.stringify(this.nodes[res.id])
        );*/
        // temp values to make the very first move
        this.nodes[res.id].qs = [...this.nodes[res.id].values];
        this.nodes[res.id].policy = policy;
      }
    }
    //
    onDone();
  }

  backfill() {
    if (this.path.length < 2) return;
    // NOTE: values contain the scores for the last move + predicted score
    let leaf = this.nodes[this.path.pop()]; // pick last element
    leaf.n++;
    let preLeafScores = this.nodes[this.path[this.path.length - 1]].engine.state
      .playerScores;
    //this.LOG(leaf.qs, leaf.ws, leaf.n);
    // NOTE: for leaf we don't need to compute the value as it has already been
    // assigned during previous expansion (even if leaf is at game over)

    // For all other nodes we need to recompute their ws and qs
    while (this.path.length > 0) {
      let node = this.nodes[this.path.pop()]; // pick last element
      node.n++;
      for (let i = 0; i < leaf.values.length; i++) {
        // we add the value from the leaf plus the score that player got to get from the node to the leaf
        node.ws[i] +=
          leaf.values[i] + preLeafScores[i] - node.engine.state.playerScores[i];
        node.qs[i] = node.ws[i] / node.n;
      }
      //this.LOG(node.qs, node.ws, node.n);
    }
  }
}
